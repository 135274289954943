import React, { useCallback, useContext, useState } from 'react';
import * as S from './SelectedAddOns.styles';
import {
  IEditAccount,
  INewAccountAddOnProps,
  LayoutContext,
} from '../../../../../contexts/layoutContext';
import { H2, Span } from '../../../../shared/typefaces/Typefaces.styles';
import { Modal } from '../../../../shared/layout';
import { SelectedAddOn } from './selectedAddOn/SelectedAddOn';
import {
  AccountType,
  useSubscriptionDataQuery,
} from '../../../../../generated/graphql';
import { Button } from '../../../../shared/button';
import { isEmpty } from '../../../../../utils/helper';

export interface IInfoModalProps {
  show: boolean;
  heading: string;
  content: string;
}

export const SelectedAddOns = () => {
  const initialInfoState = {
    show: false,
    heading: '',
    content: '',
  };
  const [infoModal, setInfoModal] = useState<IInfoModalProps>(initialInfoState);
  const [cancelledBillingModal, setCancelledBillingModal] = useState(false);
  const { newAccount, editAccount, dispatch } = useContext(LayoutContext);
  const filteredAddOns = newAccount?.addOns?.filter((a) => a.updateAddOn);
  const { data: subData } = useSubscriptionDataQuery();
  const subscriptionData = subData?.me?.user;

  const closeInfoModal = () => {
    setInfoModal(initialInfoState);
  };

  const handleCancel = () => {
    dispatch({ type: 'TOGGLE_EXTRA_SLIDER' });
  };

  const showBillingRequiredModal = useCallback(
    async (
      selectedAccount: undefined | AccountType,
      selectedAddOns: undefined | INewAccountAddOnProps[],
      force?: boolean
    ) => {
      if (
        !editAccount?.shouldBillingRedirectToEditAccountAddOnsWhenDone &&
        !subscriptionData?.stripePaymentMethodId &&
        (force || !cancelledBillingModal)
      ) {
        dispatch({
          type: 'SET_OVERLAY',
          payload: {
            heading: 'Billing Details Required',
            subHeading:
              'To upgrade your account, complete your Billing Details',
            buttonText: 'Update Billing Details',
            linkText: 'cancel',
            buttonCTA: () => {
              dispatch({
                type: 'SET_EDIT_ACCOUNT',
                payload: {
                  selectedAccount,
                  selectedAddOns,
                  shouldBillingRedirectToEditAccountAddOnsWhenDone: true,
                } as IEditAccount,
              });
              dispatch({ type: 'TOP_SLIDER_PAGE', payload: 'editBilling' });
              dispatch({ type: 'CLOSE_OVERLAY' });
            },
            linkCTA: () => {
              setCancelledBillingModal(true);
              dispatch({ type: 'CLOSE_OVERLAY' });
            },
          },
        });
      }
    },
    [editAccount, cancelledBillingModal, subscriptionData, dispatch]
  );

  const handleContinue = async () => {
    // Show billing overlay if user has no billing details
    if (
      !subscriptionData?.stripeCustomerId ||
      !subscriptionData?.stripePaymentMethodId
    ) {
      showBillingRequiredModal(newAccount?.type, newAccount?.addOns, true);
    } else {
      // Ask user to confirm new account type
      dispatch({
        type: 'SET_EDIT_ACCOUNT',
        payload: {
          selectedAccount: newAccount?.type,
          selectedAddOns: newAccount?.addOns,
        } as IEditAccount,
      });
    }
  };

  return (
    <S.Container>
      <S.AddOns>
        <S.Header>
          <H2 className="heading">
            {isEmpty(filteredAddOns)
              ? 'No Selected Add Ons'
              : 'Selected Add Ons'}
          </H2>
          {isEmpty(filteredAddOns) && (
            <S.CTAs>
              <Span color="grey" className="skip" onClick={handleCancel}>
                Cancel
              </Span>
              <Button
                onClick={handleContinue}
                color={isEmpty(filteredAddOns) ? 'default' : 'secondary'}
                className="continue"
                disabled={isEmpty(filteredAddOns)}
              >
                Continue
              </Button>
            </S.CTAs>
          )}
        </S.Header>
        {filteredAddOns &&
          filteredAddOns.map((addOn) => (
            <SelectedAddOn addOn={addOn} setInfoModal={setInfoModal} />
          ))}
        {!isEmpty(filteredAddOns) && (
          <S.Footer>
            <S.CTAs>
              <Span color="grey" className="skip" onClick={handleCancel}>
                Cancel
              </Span>
              <Button
                onClick={handleContinue}
                color={isEmpty(filteredAddOns) ? 'default' : 'secondary'}
                className="continue"
                disabled={isEmpty(filteredAddOns)}
              >
                Continue
              </Button>
            </S.CTAs>
          </S.Footer>
        )}
      </S.AddOns>
      <Modal
        showModal={infoModal.show}
        handleClose={closeInfoModal}
        heading={infoModal.heading}
        content={infoModal.content}
      />
    </S.Container>
  );
};
