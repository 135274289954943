import React, { useContext, useState } from 'react';
import * as S from './SelectAccountAddonsPage.styles';
import { addOnsArray, FAQs } from '../../../utils/helper/strings';
import { AngleBox } from '../../shared/layout';
import { FAQ } from '../../shared/layout/faq';
import { H2, Span, H3 } from '../../shared/typefaces/Typefaces.styles';
import logo from '../../../images/logo-min.png';
import smoothie from '../../../images/smoothie-min.png';
import { Arrow } from '../../shared/icons';
import { LayoutContext } from '../../../contexts/layoutContext';
import { BillingCycle } from '../../../generated/graphql';
import { SelectAccountAddOn } from './sections/addOn';
import { SelectedAddOns } from './sections/selectedAddOns';
import { isFreeAccount } from '../../../utils/helper/subscription';
import { useHistory } from 'react-router-dom';
import { Button } from '../../shared/button';

export const SelectAccountAddonsPage = () => {
  const { newAccount, dispatch } = useContext(LayoutContext);
  const freeAccount = isFreeAccount(newAccount?.type);
  const initialShowFreeAccountYearlyMessage =
    freeAccount && newAccount?.billingCycle === BillingCycle.Yearly;
  const [
    showFreeAccountYearlyMessage,
    setShowFreeAccountYearlyMessage,
  ] = useState(initialShowFreeAccountYearlyMessage);
  const { push } = useHistory();

  const setBillingCycle = (billingCycle: BillingCycle) => {
    dispatch({
      type: 'SET_NEW_ACCOUNT',
      payload: { ...newAccount, billingCycle },
    });

    setShowFreeAccountYearlyMessage(
      freeAccount && billingCycle === BillingCycle.Yearly
    );
  };

  const handleBack = () => {
    push('/select-account');
  };

  return (
    <S.Container>
      <AngleBox color="default" marginTop="huge" />
      <S.Main marginTop="huge">
        <S.Content>
          <S.Header>
            <S.Left>
              <S.Heading onClick={handleBack}>
                <div className="backButton">
                  <Arrow size="smaller" className="backArrow" rotate={180} />
                </div>
                Add Ons
              </S.Heading>
              <H2 className="subHeading">
                <Span className="subHeadingTop">
                  Upgrade Your Performance &
                </Span>
                <Span className="subHeadingBottom">
                  Streamline Your Workflow
                </Span>
              </H2>
            </S.Left>
            <S.Logo className="logo" src={logo} />
          </S.Header>
          <S.Callout>
            <H2 className="heading">Add To Any Account, Anytime.</H2>
            <S.BreakLine />
            <Arrow color="grey" size="small" rotate={90} />
          </S.Callout>
          <S.BillingCycle
            selected={newAccount?.billingCycle === BillingCycle.Yearly}
          >
            <S.Tab
              selected={newAccount?.billingCycle === BillingCycle.Monthly}
              className="tab"
              onClick={() => setBillingCycle(BillingCycle.Monthly)}
            >
              Monthly
            </S.Tab>
            <S.Tab
              selected={newAccount?.billingCycle === BillingCycle.Yearly}
              className="tab"
              onClick={() => setBillingCycle(BillingCycle.Yearly)}
            >
              Yearly
            </S.Tab>
          </S.BillingCycle>
          <S.YearlyBillingMessage>
            {showFreeAccountYearlyMessage && (
              <Button
                color="primary"
                onClick={handleBack}
                className="upgradeAccount"
                size="large"
              >
                <Span className="message">
                  <Span className="center">Upgrade account to recieve</Span>
                  <br />
                  <Span className="center">Yearly discounts **</Span>
                </Span>
              </Button>
            )}
          </S.YearlyBillingMessage>
          <S.AddOns>
            {addOnsArray.map((a) => (
              <SelectAccountAddOn addOn={a} />
            ))}
          </S.AddOns>
          <S.AccountHeader>
            <H3 className="accountHeading">See Fresh Profit Immediately</H3>
            <Span className="accountSubHeading">
              Upgrade Your Performance, Today
            </Span>
          </S.AccountHeader>
          <SelectedAddOns />
        </S.Content>
        <H3 className="questionsHeader">Further Questions</H3>
        <FAQ questions={FAQs} />
        <S.FooterImage>
          <img src={smoothie} alt="Smoothie" className="footerImage" />
        </S.FooterImage>
      </S.Main>
    </S.Container>
  );
};
