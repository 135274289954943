import styled from 'styled-components';
import { LayoutColumnContainer } from '../../../../shared/layout/layoutColumn/LayoutColumn.styles';
import { device } from '../../../../../styles/device';
import { Card } from '../../../../shared/card';

export const Container = styled(Card)`
  max-width: 500px;
  margin: 30px auto;
`;

export const Header = styled.section`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  align-items: center;
  min-height: 50px;
  border-radius: 14px 14px 0 0;
  padding: 5px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};
`;

export const Form = styled.form`
  padding: 15px;

  ${LayoutColumnContainer} {
    padding: 0;
    grid-template-columns: auto;

    @media ${device.tablet} {
      grid-template-columns: 160px auto;
    }
  }

  .errorMessage {
    color: ${({ theme }) => theme.colors.text.error};
    line-height: 20px;
    padding: 0 15px 15px 0;
    display: block;
  }
`;
