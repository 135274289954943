const greyLightest = '#F8F8F8';
const greyFaded = '#999'; //"#858585";
const grey = '#666';
const black = '#000';
const primary = '#F98D0F';
const secondary = '#1BAB3A';
const white = '#FFFFFF';
const defaultLight = 'rgb(241,237,231, 1)';
const defaultLightest = '#F1EDE7';
const defaultLightTransparent = 'rgb(241,237,231, 0.5)';
const error = `#CD0000`;

export const theme = {
  colors: {
    primary: {
      default: primary,
      faded: '#FABA6F',
      dark: '#BF6B0C',
    },
    secondary: {
      default: secondary,
      faded: '#74B883',
      dark: '#137B29',
    },
    default: {
      default: '#CDC0B2',
      faded: '#D7CCC1',
      light: defaultLight,
      lightest: defaultLightest,
      transparent: defaultLightTransparent,
    },
    error: {
      default: error,
      faded: 'rgba(205, 0, 0, 0.5)',
    },
    grey: {
      default: grey,
      lightest: greyLightest,
      faded: greyFaded,
      dark: black,
    },
    white: {
      default: white,
      faded: 'rgba(255, 255, 255, 0.8)',
      light: 'rgba(255, 255, 255, 0.2)',
    },
    text: {
      default: '#343434',
      faded: greyFaded,
      grey: grey,
      secondary: secondary,
      primary: primary,
      black: black,
      white: white,
      error: 'red',
    },
    background: defaultLight,
    backgroundFaded: defaultLightTransparent,
    brown: '#BF6B0C',
    black: black,
    blue: '#009EE1',
    purple: '#8F00FF',
  },
  fontFamily: 'Montserrat, sans-serif',
  shadow: {
    box: '0px 0px 25px rgba(0, 0, 0, 0.15);',
    boxHover: '0px 0px 25px rgba(0, 0, 0, 0.40);',
    tinyBox: '0px 0px 10px 0 rgba(0, 0, 0, 0.15);',
    smallBox: '0px 0px 15px 0 rgba(0, 0, 0, 0.15);',
    largeBox: '0px 0px 15px 0 rgba(0, 0, 0, 0.25)',
    text: '0px 0px 25px rgba(0, 0, 0, 0.15);',
    textHeading: '0px 0px 10px rgba(0,0,0,0.50);',
  },
  fontSize: {
    default: '16px',
    small: '13px',
    button: '14px',
    medium: '20px',
    large: '24px',
    heading: '30px',
    largeHeading: '40px',
    title: '50px',
  },
  lineHeight: {
    button: '14px',
    default: '20px',
    small: '18px',
    medium: '20px',
    large: '24px',
    heading: '30px',
    largeHeading: '40px',
    title: '50px',
  },
  fontWeight: {
    bold: '700',
    semibold: '600',
    medium: '500',
    regular: '400',
    light: '100',
  },
  layout: {
    pagePadding: '0 15px',
    padding: '15px',
    smallButtonWidth: '80px',
    buttonWidth: '160px',
    smallButtonHeight: '30px',
    buttonHeight: '40px',
    largeButtonHeight: '60px',
    overScroll: '100px',
    radiusLarge: '30px',
    radiusSmall: '20px',
    radiusTiny: '15px',
    radiusSmallest: '8px',
  },
  size: {
    tiny: '10px',
    smaller: '15px',
    small: '20px',
    medium: '30px',
    large: '40px',
    xlarge: '70px',
    default: '50px',
  },
  form: {
    input: {
      marginTop: '8px',
    },
  },
  mQ: {
    mobileXS: 320,
    mobileS: 380,
    mobileM: 480,
    mobileL: 630,
    tablet: 750,
    tabletL: 815,
    laptop: 1024,
    laptopL: 1220,
    desktopS: 1350,
    desktop: 2560,
  },
  zIndex: {
    below: '-1',
    default: '0',
    preAbove: '1',
    above: '2',
    nav: '3',
    aboveNav: '4',
    priority: '5',
    overlay: '9',
    menu: '10',
    toggle: '15',
    top: '20',
  },
};
