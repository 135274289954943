import React, { FC, useState } from 'react';
import * as S from './DashboardUsers.styles';
import { Arrow } from '../../../../shared/icons';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';
import { Maybe, Recipe, User } from '../../../../../generated/graphql';
import {
  capitalizeFirstLetterPerWord,
  formatDateFromTimestamp,
} from '../../../../../utils/helper';
import {
  getAccountTypeText,
  getSubscriptionStatusText,
} from '../../../../../utils/helper/strings';

interface IUserRowProps {
  user?:
    | Maybe<
        {
          __typename?: 'User';
        } & Pick<
          User,
          | 'firstName'
          | 'email'
          | 'id'
          | 'isEmailConfirmed'
          | 'lastName'
          | 'phone'
          | 'internationalPhone'
          | 'position'
          | 'accountType'
          | 'billingCycle'
          | 'subscriptionStatus'
          | 'lastLoginAt'
          | 'createdAt'
          | 'updatedAt'
        > & {
            recipes: Array<
              {
                __typename?: 'Recipe';
              } & Pick<Recipe, 'creatorId'>
            >;
          }
      >
    | undefined;
}

export const UserRow: FC<IUserRowProps> = ({ user }) => {
  const [showUser, setShowUser] = useState(false);
  const date = formatDateFromTimestamp(user?.createdAt || '');
  return (
    <S.User>
      <S.UserHeader showUser={showUser} onClick={() => setShowUser(!showUser)}>
        <div>
          <Span color="black" fontWeight="medium">
            {user?.firstName} {user?.lastName}:{' '}
          </Span>
          <Span color="grey">{user?.email}</Span>
        </div>
        <S.HeaderRight>
          <Span color="faded">{date}</Span>
          <S.DropdownIcon>
            <Arrow rotate={showUser ? 270 : 90} size="small" color="default" />
          </S.DropdownIcon>
        </S.HeaderRight>
      </S.UserHeader>
      {showUser && (
        <S.UserContent>
          <S.ContentItem>
            <Span color="black" fontWeight="medium">
              ID:{' '}
            </Span>
            <Span color="grey">{user?.id}</Span>
          </S.ContentItem>
          <S.ContentItem>
            <Span color="black" fontWeight="medium">
              Account Type:{' '}
            </Span>
            <Span color="grey">{getAccountTypeText(user?.accountType)}</Span>
          </S.ContentItem>
          <S.ContentItem>
            <Span color="black" fontWeight="medium">
              Billing Cycle:{' '}
            </Span>
            <Span color="grey">
              {capitalizeFirstLetterPerWord(user?.billingCycle)}
            </Span>
          </S.ContentItem>
          <S.ContentItem>
            <Span color="black" fontWeight="medium">
              Subscription Status:{' '}
            </Span>
            <Span color="grey">
              {getSubscriptionStatusText(user?.subscriptionStatus)}
            </Span>
          </S.ContentItem>
          <S.ContentItem>
            <Span color="black" fontWeight="medium">
              Recipes:{' '}
            </Span>
            <Span color="grey">{user?.recipes.length}</Span>
          </S.ContentItem>
          <br />
          <S.ContentItem>
            <Span color="black" fontWeight="medium">
              Position:{' '}
            </Span>
            <Span color="grey">
              {capitalizeFirstLetterPerWord(user?.position)}
            </Span>
          </S.ContentItem>
          <S.ContentItem>
            <Span color="black" fontWeight="medium">
              Phone:{' '}
            </Span>
            <Span color="grey">{user?.phone || 'N/a'}</Span>
          </S.ContentItem>
          <S.ContentItem>
            <Span color="black" fontWeight="medium">
              International Phone:{' '}
            </Span>
            <Span color="grey">{user?.internationalPhone || 'N/a'}</Span>
          </S.ContentItem>
          <S.ContentItem>
            <Span color="black" fontWeight="medium">
              Last Login:{' '}
            </Span>
            <Span color="grey">{user?.lastLoginAt}</Span>
          </S.ContentItem>
        </S.UserContent>
      )}
    </S.User>
  );
};
