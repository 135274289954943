import { AccountType, BillingCycle } from '../../generated/graphql';
import { plansArray } from './strings';

export const isPaidAccount = (accountType?: AccountType) => {
  if (!accountType) {
    return false;
  }
  return (
    accountType === AccountType.RecipePlus ||
    accountType === AccountType.HeadChef ||
    accountType === AccountType.Owner ||
    accountType === AccountType.Feedback ||
    accountType === AccountType.RecipeRevenue
  );
};

export const isFreeAccount = (accountType?: AccountType) => {
  if (!accountType) {
    return false;
  }
  return accountType === AccountType.Registered;
};

export const getSubscriptionCost = (
  type?: AccountType,
  billingCycle?: BillingCycle
) => {
  const plan = plansArray.find((p) => p.type === type);

  if (
    !plan ||
    plan.type === AccountType.Guest ||
    plan.type === AccountType.Registered ||
    plan.type === AccountType.Feedback ||
    plan.type === AccountType.RecipeRevenue
  ) {
    return '';
  }

  if (billingCycle === BillingCycle.Yearly) {
    return `$${Number(plan.price) * 12}.00`;
  }

  return `$${plan.monthlyPrice}.00`;
};
