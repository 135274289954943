import { useContext } from 'react';
import { AccountType } from '../../generated/graphql';
import { LayoutContext } from '../../contexts/layoutContext';
import { useRestrictionCheck, userHasCalculatorAddon } from '../helper/account';

export const useShowCalcData = () => {
  const { account } = useContext(LayoutContext);
  const checkRecipeProfitRestrictions = useRestrictionCheck([
    'CALCULATE_RECIPE_PROFIT',
  ]);
  let showCalcData = false;

  if (account && account?.addOns) {
    const { validUserCalculatorAddOn } = userHasCalculatorAddon(
      account?.addOns
    );
    const restrictionCheck = checkRecipeProfitRestrictions({
      data:
        account?.type === AccountType.HeadChef ||
        account?.type === AccountType.Owner ||
        account?.type === AccountType.Feedback ||
        account?.type === AccountType.RecipeRevenue,
    });
    showCalcData = validUserCalculatorAddOn || restrictionCheck.isPass;
  }
  return {
    showCalcData,
  };
};
