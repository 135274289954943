import React, { useContext, useState } from 'react';
import * as S from './SelectedAddOns.styles';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { H2, Span } from '../../../../shared/typefaces/Typefaces.styles';
import { Modal } from '../../../../shared/layout';
import { SelectedAddOn } from './selectedAddOn/SelectedAddOn';
import { useHistory } from 'react-router-dom';
import {
  AccountType,
  BillingCycle,
  useMeQuery,
} from '../../../../../generated/graphql';
import { isPaidAccount } from '../../../../../utils/helper/subscription';
import { checkIfAccountIsNotComplete } from '../../../../../utils/helper/account';
import { Button } from '../../../../shared/button';
import { isEmpty } from '../../../../../utils/helper';

export interface IInfoModalProps {
  show: boolean;
  heading: string;
  content: string;
}

export const SelectedAddOns = () => {
  const { data } = useMeQuery();
  const initialInfoState = {
    show: false,
    heading: '',
    content: '',
  };
  const [infoModal, setInfoModal] = useState<IInfoModalProps>(initialInfoState);
  const { newAccount, dispatch } = useContext(LayoutContext);
  const { push } = useHistory();
  const filteredAddOns = newAccount?.addOns?.filter((a) => a.updateAddOn);

  const closeInfoModal = () => {
    setInfoModal(initialInfoState);
  };

  const handleSkip = () => {
    dispatch({
      type: 'SET_NEW_ACCOUNT',
      payload: {
        type: newAccount?.type,
        billingCycle: newAccount?.billingCycle,
        addOns: [],
      },
    });
    push('/sign-up');
  };

  const handleContinue = async () => {
    // If free acocunt set biling cycle back to monthly
    if (
      newAccount?.billingCycle === BillingCycle.Yearly &&
      newAccount.type === AccountType.Registered
    ) {
      dispatch({
        type: 'SET_NEW_ACCOUNT',
        payload: {
          ...newAccount,
          billingCycle: BillingCycle.Monthly,
        },
      });
    }
    const newAccountPaymentCheck = isPaidAccount(newAccount?.type);
    if (!newAccount?.type) {
      push('/select-account');
    } else if (
      !data?.me?.user ||
      checkIfAccountIsNotComplete(
        data?.me?.user?.email,
        data?.me?.user?.accountType
      )
    ) {
      push('/sign-up');
    } else {
      if (newAccountPaymentCheck) {
        push('/payment');
      } else {
        push('/recipe-results');
      }
    }
  };

  return (
    <S.Container>
      <S.AddOns>
        <S.Header>
          <H2 className="heading">
            {isEmpty(filteredAddOns)
              ? 'No Selected Add Ons'
              : 'Selected Add Ons'}
          </H2>
          {isEmpty(filteredAddOns) && (
            <S.CTAs>
              <Span color="grey" className="skip" onClick={handleSkip}>
                Skip
              </Span>
              <Button
                onClick={handleContinue}
                color="secondary"
                className="continue"
              >
                Continue
              </Button>
            </S.CTAs>
          )}
        </S.Header>
        {filteredAddOns &&
          filteredAddOns.map((addOn) => (
            <SelectedAddOn addOn={addOn} setInfoModal={setInfoModal} />
          ))}
        {!isEmpty(filteredAddOns) && (
          <S.Footer>
            <S.CTAs>
              <Span color="grey" className="skip" onClick={handleSkip}>
                Skip
              </Span>
              <Button
                onClick={handleContinue}
                color="secondary"
                className="continue"
              >
                Continue
              </Button>
            </S.CTAs>
          </S.Footer>
        )}
      </S.AddOns>
      <Modal
        showModal={infoModal.show}
        handleClose={closeInfoModal}
        heading={infoModal.heading}
        content={infoModal.content}
      />
    </S.Container>
  );
};
