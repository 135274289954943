import React, { FC } from 'react';
import { IInfoModalProps } from '../../SelectAccount';
import * as S from './AddOns.styles';
import { H3, H4 } from '../../../../shared/typefaces/Typefaces.styles';
import { addOnsArray } from '../../../../../utils/helper/strings';
import { AddOn } from '../addOn';

interface IAddOnsProps {
  setInfoModal: React.Dispatch<React.SetStateAction<IInfoModalProps>>;
  isAccountPage?: boolean;
}

export const AddOns: FC<IAddOnsProps> = ({ isAccountPage, setInfoModal }) => {
  return (
    <S.Container>
      <S.Addons>
        <H3 className="heading">Add Ons</H3>
        {addOnsArray.map((a, idx) => {
          return (
            <AddOn
              key={idx}
              addOn={a}
              setInfoModal={setInfoModal}
              isAccountPage={isAccountPage}
            />
          );
        })}
        <H4 className="footer">
          *All prices are in Australian Dollars (AUD) and includes GST
        </H4>
      </S.Addons>
    </S.Container>
  );
};
