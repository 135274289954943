import React, { FC, useContext, useState } from 'react';
import { ErrorIcon } from '../../../../shared/icons';
import { H4, Span } from '../../../../shared/typefaces/Typefaces.styles';
import * as S from './SelectAccountAddOn.styles';
import { IAddOnOptionProps } from '../../../../../utils/helper/strings';
import {
  INewAccountAddOnProps,
  LayoutContext,
} from '../../../../../contexts/layoutContext';
import { AddOnApp, BillingCycle } from '../../../../../generated/graphql';
import { AddOnHeader } from '../addOnHeader';
import { theme } from '../../../../../styles/theme';
import mobileImage from '../../../../../images/mobile-add-on-min.png';

interface IAddOnProps {
  addOn: IAddOnOptionProps;
}

export const SelectAccountAddOn: FC<IAddOnProps> = ({ addOn }) => {
  const { appWidth, newAccount, dispatch } = useContext(LayoutContext);
  const [showContent, setShowContent] = useState(false);
  const {
    savingDescription,
    extraContentHeading,
    extraContentText,
    contentImages,
    addOnApp,
  } = addOn;

  const handleAddOnClick = (addOnApp: AddOnApp) => {
    const alreadyHasAddonType = newAccount?.addOns?.find((a) => {
      return a.app === addOnApp;
    });

    const updatedAddOns: INewAccountAddOnProps[] = alreadyHasAddonType
      ? newAccount!.addOns!.map((a) => {
          if (a.app === addOnApp) {
            // Update addOn
            return {
              ...a,
              updateAddOn: true,
            };
          }
          // Skip addOn
          return a;
        })
      : // New addOn
        [
          {
            app: addOnApp,
            endDate: '',
            updateAddOn: true,
          },
        ];

    dispatch({
      type: 'SET_NEW_ACCOUNT',
      payload: {
        ...newAccount,
        addOns: updatedAddOns,
      },
    });
  };

  const showDiscount = newAccount?.billingCycle === BillingCycle.Yearly;

  return (
    <S.Container>
      <S.Header>
        {showContent && (
          <Span
            color="faded"
            className="close"
            onClick={() => setShowContent(false)}
          >
            Close
            <ErrorIcon size="smaller" className="errorIcon" />
          </Span>
        )}
        <S.Discount
          color="secondary"
          fontSize="small"
          addPadding={!showContent}
        >
          {showDiscount && savingDescription}
        </S.Discount>
      </S.Header>
      <S.AddOn>
        <AddOnHeader
          addOn={addOn}
          showContent={showContent}
          handleAddOnClick={() => handleAddOnClick(addOnApp)}
          handleShowContent={() => setShowContent(true)}
        />
        {showContent && (
          <>
            <S.Content>
              {extraContentHeading && (
                <H4 className="extraContentHeading">{extraContentHeading}</H4>
              )}
              {extraContentText && (
                <Span className="extraContentText">{extraContentText}</Span>
              )}
              {appWidth <= theme.mQ.tablet ? (
                <S.Images>
                  <S.Image src={mobileImage} />
                </S.Images>
              ) : (
                contentImages && (
                  <S.Images>
                    {contentImages.map((image) => (
                      <S.Image src={image} />
                    ))}
                  </S.Images>
                )
              )}
            </S.Content>
            <AddOnHeader
              addOn={addOn}
              handleAddOnClick={() => handleAddOnClick(addOnApp)}
              footer
              showContent={showContent}
              handleShowContent={() => setShowContent(true)}
            />
          </>
        )}
      </S.AddOn>
    </S.Container>
  );
};
