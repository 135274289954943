import React, { FC, useContext } from 'react';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import {
  AccountType,
  AddOnApp,
  BillingCycle,
  MeQuery,
  MeQueryVariables,
} from '../../../../../generated/graphql';
import * as ApolloClient from '@apollo/client';
import { Button } from '../../../../shared/button';
import { Tick } from '../../../../shared/icons';
import { DotsLoading } from '../../../../shared/loading';
import { H3, Span } from '../../../../shared/typefaces/Typefaces.styles';
import * as S from './PlanOption.styles';
import { validEndDate } from '../../../../../utils/helper/time';

export interface IPlanOptionProps {
  type: AccountType;
  addOnApp?: AddOnApp;
  addOnPromoHeading?: string;
  addOnPromoText?: string;
  heading: string;
  subHeading: string;
  price: string;
  priceDescription: string;
  monthlyPrice: string;
  monthlyPriceDescription: string;
  billedAnnually: boolean;
  CTA: string;
  features: string[];
  isLoading: boolean;
  accountTypePreview?: AccountType;
  savingDescription?: string;
  me?: ApolloClient.QueryResult<MeQuery, MeQueryVariables>;
  confirmSelection?: boolean;
  calcAddOnSelected?: boolean;
  onSubmit: (accountType: AccountType) => void;
  openBaseAccountModal: (addOnApp: AddOnApp) => void;
}

export const PlanOption: FC<IPlanOptionProps> = ({
  type,
  addOnApp,
  addOnPromoHeading,
  addOnPromoText,
  heading,
  subHeading,
  price,
  priceDescription,
  monthlyPrice,
  monthlyPriceDescription,
  billedAnnually,
  CTA,
  features,
  isLoading,
  accountTypePreview,
  savingDescription,
  me,
  confirmSelection,
  calcAddOnSelected,
  onSubmit,
  openBaseAccountModal,
}) => {
  const { account, newAccount } = useContext(LayoutContext);
  const currentAddOn = me?.data?.me?.user?.addOns.find((a) => {
    return validEndDate(a.endDate).endDateIsValid && a.app === addOnApp;
  });

  const addOn: AddOnApp | undefined =
    newAccount?.addOns?.find((a) => a.app === addOnApp)?.app ||
    currentAddOn?.app;

  const selectedPreviewUserAccount = accountTypePreview === type;
  const addOnIsUpdating =
    accountTypePreview === newAccount?.type && addOn && addOn === addOnApp;
  const addedOn = 'Added On';
  const selected = 'Selected';
  const confirm = 'Confirm';

  const handleSelectOption = () => {
    if (addOnApp) {
      openBaseAccountModal(addOnApp);
    } else {
      onSubmit(type);
    }
  };

  const getButtonText = () => {
    if (selectedPreviewUserAccount) {
      return 'Updating';
    }
    if (newAccount?.type === type) {
      if (!account) {
        return confirmSelection ? confirm : selected;
      }

      if (newAccount?.billingCycle === account?.billingCycle) {
        return confirmSelection ? confirm : selected;
      }
    }
    return CTA;
  };

  const getAddOnButtonText = () => {
    if (!addOnApp || !addOn) {
      return undefined;
    }
    if (addOnIsUpdating) {
      return 'Updating';
    }

    if (addOn === AddOnApp.Calculator) {
      if (currentAddOn?.app) {
        return 'Add More?';
      }
      return calcAddOnSelected ? addedOn : CTA;
    }
    return CTA;
  };

  const buttonText = getButtonText();
  const addOnButtonText = getAddOnButtonText();
  const isInversed = buttonText === CTA;
  const isAddedOn =
    addOnIsUpdating || addedOn === addOnButtonText || currentAddOn?.app;
  const yearlyBillingCycle = newAccount?.billingCycle === BillingCycle.Yearly;
  const btnText = addOnButtonText || buttonText;

  return (
    <S.Container type={type} onClick={handleSelectOption}>
      {yearlyBillingCycle && savingDescription && (
        <S.Savings>{savingDescription}</S.Savings>
      )}
      <S.Header>
        <H3 className="heading">{heading}</H3>
        <Span className="subHeading">{subHeading}</Span>
      </S.Header>
      <S.Price>
        <S.PriceRow>
          <Span className="dollarIcon">$</Span>
          <Span className="price">
            {yearlyBillingCycle ? price : monthlyPrice}
          </Span>
          {yearlyBillingCycle && billedAnnually && (
            <S.BillingDescription>Billed Anually</S.BillingDescription>
          )}
        </S.PriceRow>
        <Span className="priceDescription">
          {yearlyBillingCycle ? priceDescription : monthlyPriceDescription}
        </Span>
      </S.Price>
      <S.Details>
        <Button
          className="CTA"
          inversed={isAddedOn ? false : isInversed}
          color={
            selectedPreviewUserAccount || isAddedOn ? 'secondary' : 'primary'
          }
          onClick={handleSelectOption}
          size="default"
        >
          <DotsLoading
            text={() => btnText}
            isLoading={
              !!selectedPreviewUserAccount || addOnIsUpdating || isLoading
            }
            size="small"
            lineHeight={10}
            noMargin
          />
        </Button>
        <S.Features>
          {features.map((feature, idx) => {
            return (
              <S.Feature key={idx}>
                <Tick color="secondary" size="tiny" />
                <Span className="feature">{feature}</Span>
              </S.Feature>
            );
          })}
        </S.Features>
      </S.Details>

      {addOnPromoHeading && addOnPromoText && (
        <S.AddOn>
          <Span className="addOnHeading">{addOnPromoHeading}</Span>
          <Span className="addOnContent">{addOnPromoText}</Span>
        </S.AddOn>
      )}
    </S.Container>
  );
};
