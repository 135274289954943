import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';

export const Container = styled.div``;

export const AddOns = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white.default};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.tinyBox};
  margin: 0 20px 100px 20px;
  padding: 30px 20px;

  .heading {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: ${({ theme }) => theme.fontSize.large};
    margin-bottom: 0;
    width: 100%;
  }

  @media ${device.tablet} {
    padding: 30px 60px;
    margin: 0 20px 150px 20px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .heading {
    margin-bottom: 15px;
  }

  @media ${device.mobileL} {
    flex-direction: row;

    .heading {
      margin-bottom: 0;
    }
  }
`;

export const CTAs = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 30px;
  width: 100%;

  .skip {
    margin-right: 20px;
    padding: 12px 20px;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.black};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
    }
  }

  .continue {
    min-width: 130px;
  }

  @media ${device.mobileL} {
    margin-top: 0;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;
`;
