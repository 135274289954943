import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LayoutContext } from '../../../contexts/layoutContext';
import {
  AccountType,
  useMeQuery,
  useUpdateUserMutation,
} from '../../../generated/graphql';
import { FAQs } from '../../../utils/helper/strings';
import { AngleBox, Modal } from '../../shared/layout';
import { H2, H3, Span } from '../../shared/typefaces/Typefaces.styles';
import { Pricing } from './sections';
import {
  Container,
  Content,
  Header,
  Heading,
  Main,
  Left,
  Logo,
  Callout,
  AccountHeader,
  FooterImage,
} from './SelectAccount.styles';
import logo from '../../../images/logo-min.png';
import chia from '../../../images/chia.png';
import { SelectPlan } from './sections/selectPlan';
import { PlanOptions } from './sections/planOptions';
import { AddOns } from './sections/addOns';
import { FAQ } from '../../shared/layout/faq';
import { isPaidAccount } from '../../../utils/helper/subscription';
import { checkIfAccountIsNotComplete } from '../../../utils/helper/account';
import { useActiveTimeTracker } from '../../../utils/customHooks/useActiveTimeTracker';

export interface IInfoModalProps {
  show: boolean;
  heading: string;
  content: string;
}

export const SelectAccount = () => {
  const initialInfoState = {
    show: false,
    heading: '',
    content: '',
  };

  const [infoModal, setInfoModal] = useState<IInfoModalProps>(initialInfoState);
  const { newAccount, selectedVenueObject, dispatch } = useContext(
    LayoutContext
  );
  const [updateUserMutation, updateUser] = useUpdateUserMutation();
  const { getTotalActiveSeconds } = useActiveTimeTracker();
  const { data } = useMeQuery();
  const { push } = useHistory();

  const closeInfoModal = () => {
    setInfoModal(initialInfoState);
  };

  useEffect(() => {
    dispatch({ type: 'CLOSE_MENU' });
  }, [dispatch]);

  const onSubmit = async (accountType: AccountType) => {
    const newAccountIsPaidAccount = isPaidAccount(accountType);
    const showAddOns =
      accountType === AccountType.Registered ||
      accountType === AccountType.RecipePlus;

    if (data?.me?.successful) {
      try {
        const response = await updateUserMutation({
          variables: {
            input: {
              accountType,
              venueId: selectedVenueObject?.id!,
              totalActiveSeconds: getTotalActiveSeconds(),
            },
          },
          refetchQueries: ['useMeQuery'],
        });
        if (response.data?.updateUser.successful) {
          const { user } = response.data.updateUser;
          dispatch({
            type: 'SET_ACCOUNT',
            payload: {
              type: user?.accountType,
              billingCycle: user?.billingCycle,
              addOns: user?.addOns,
            },
          });
          dispatch({
            type: 'SET_NEW_ACCOUNT',
            payload: {
              ...newAccount,
              type: user?.accountType,
              billingCycle: user?.billingCycle,
              addOns: user?.addOns,
            },
          });

          if (checkIfAccountIsNotComplete(user?.email, user?.accountType)) {
            push(showAddOns ? '/select-account/addOns' : '/sign-up');
          } else {
            if (newAccountIsPaidAccount) {
              push(showAddOns ? '/select-account/addOns' : '/payment');
            } else {
              push(showAddOns ? '/select-account/addOns' : '/recipe-results');
            }
          }
        }
      } catch (err) {
        console.log('Error', err);
      }
    } else {
      dispatch({
        type: 'SET_NEW_ACCOUNT',
        payload: {
          ...newAccount,
          addOns: showAddOns ? newAccount?.addOns : [],
          type: accountType,
        },
      });
      push(showAddOns ? '/select-account/addOns' : '/sign-up');
    }
  };
  const isLoading = updateUser.loading;

  return (
    <Container>
      <AngleBox color="default" marginTop="huge" />
      <Main marginTop="huge">
        <Content>
          <Header>
            <Left>
              <Heading>Pricing</Heading>
              <H2 className="subHeading">
                <Span className="subHeadingTop">
                  Accurately Create Delicious,
                </Span>
                <Span className="subHeadingBottom">
                  Profitable Recipes In Minutes
                </Span>
              </H2>
            </Left>
            <Logo className="logo" src={logo} />
          </Header>
          <SelectPlan onSubmit={onSubmit} isLoading={isLoading} />
          <AddOns setInfoModal={setInfoModal} />
          <Callout>
            <H3 className="callout">
              For creative Chef’s who run the kitchen like a business, who know
              the key to consistent quality is knowing the numbers
            </H3>
          </Callout>
        </Content>
        <Pricing
          onSubmit={onSubmit}
          isLoading={isLoading}
          setInfoModal={setInfoModal}
        />
        <Content>
          <AccountHeader>
            <H3 className="accountHeading">See Fresh Profit Immediately</H3>
            <Span className="accountSubHeading">1 minute account set up</Span>
          </AccountHeader>
          <PlanOptions onSubmit={onSubmit} isLoading={isLoading} />
          <AddOns setInfoModal={setInfoModal} />
        </Content>
        <H3 className="questionsHeader">Further Questions</H3>
        <FAQ questions={FAQs} />
        <FooterImage>
          <img src={chia} alt="chia pudding" className="footerImage" />
        </FooterImage>
        <Modal
          showModal={infoModal.show}
          handleClose={closeInfoModal}
          heading={infoModal.heading}
          content={infoModal.content}
        />
      </Main>
    </Container>
  );
};
