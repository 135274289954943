import React, { FC, useContext } from 'react';
import * as S from './AddOnHeader.styles';
import { H2, Span } from '../../../../shared/typefaces/Typefaces.styles';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { BillingCycle } from '../../../../../generated/graphql';
import { IAddOnOptionProps } from '../../../../../utils/helper/strings';
import { Button } from '../../../../shared/button';
import {
  formatDateFromTimestamp,
  validEndDate,
} from '../../../../../utils/helper';
import { isFreeAccount } from '../../../../../utils/helper/subscription';

interface IAddOnHeaderProps {
  addOn: IAddOnOptionProps;
  footer?: boolean;
  showContent: boolean;
  handleAddOnClick: () => void;
  handleShowContent: () => void;
}

export const AddOnHeader: FC<IAddOnHeaderProps> = ({
  addOn: {
    monthlyPrice,
    monthlyPriceDescription,
    priceDescription,
    price,
    heading,
    info,
    addOnApp,
  },
  footer,
  showContent,
  handleAddOnClick,
  handleShowContent,
}) => {
  const { account, newAccount } = useContext(LayoutContext);
  const freeAccount = isFreeAccount(newAccount?.type);
  const yearlyBillingCycle = newAccount?.billingCycle === BillingCycle.Yearly;
  const accountAddOn = account?.addOns?.find((a) => a.app === addOnApp);
  const newAccountAddOn = newAccount?.addOns?.find(
    (a) => a.app === addOnApp && a.updateAddOn
  );
  const { endDateIsValid } = validEndDate(accountAddOn?.endDate);
  const endDate =
    endDateIsValid && formatDateFromTimestamp(accountAddOn!.endDate);
  const added = newAccountAddOn;

  return (
    <S.Container>
      <S.Header footer={footer}>
        <S.Left>
          <H2 className="title">{heading}</H2>
          <Span className="info">{info}</Span>
          {!showContent && (
            <Button onClick={handleShowContent} className="CTA" color="primary">
              Learn More
            </Button>
          )}
        </S.Left>
        <S.Right footer={footer}>
          <S.RightContent footer={footer}>
            <Span className="price">
              <Span className="dollarIcon">$</Span>
              {yearlyBillingCycle && !freeAccount ? price : monthlyPrice}
            </Span>
            <Span className="priceDescription">
              {yearlyBillingCycle && !freeAccount
                ? priceDescription
                : monthlyPriceDescription}
            </Span>
            {endDateIsValid && (
              <Span className="valid">{`Valid Until: ${endDate}`}</Span>
            )}

            {!endDateIsValid && (
              <Button
                onClick={handleAddOnClick}
                color="secondary"
                className="CTA"
              >
                {added ? 'Added' : 'Add'}
              </Button>
            )}
          </S.RightContent>
        </S.Right>
      </S.Header>
    </S.Container>
  );
};
