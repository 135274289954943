import styled from 'styled-components/macro';
import { AccountType } from '../../../../../generated/graphql';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';

export interface ITypeProps {
  type: AccountType;
}

interface IPriceRow {
  hide?: boolean;
}

export const getAccountTypeColor = (theme: any, type?: AccountType) => {
  switch (type) {
    case AccountType.Registered:
      return theme.colors.blue;
    case AccountType.RecipePlus:
      return theme.colors.secondary.default;
    case AccountType.HeadChef:
      return theme.colors.primary.default;
    case AccountType.Owner:
      return theme.colors.purple;
    default:
      return theme.colors.text.default;
  }
};

export const Container = styled.div<ITypeProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 200px;
  margin: 10px;
  background-color: ${({ theme }) => theme.colors.default.transparent};
  box-shadow: ${({ theme }) => theme.shadow.box};
  border-radius: 8px;
  margin-bottom: 20px;
  cursor: pointer;

  .heading {
    color: ${({ type, theme }) => getAccountTypeColor(theme, type)};
    font-size: ${({ theme }) => theme.fontSize.medium};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    margin: 8px 0 20px 0;
    line-height: 0;
  }

  .dollarIcon {
    display: ${({ type }) =>
      type === AccountType.Registered ? 'none' : 'block'};
    position: absolute;
    top: -5px;
    font-size: ${({ theme }) => theme.fontSize.small};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.text.grey};
  }

  .price {
    font-size: ${({ theme }) => theme.fontSize.large};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.colors.text.grey};
    margin-left: ${({ type }) =>
      type === AccountType.Registered ? '0' : '10px'};
  }

  .priceDescription {
    font-size: ${({ theme }) => theme.fontSize.small};
  }

  .CTA {
    margin: 10px auto;
  }

  &:hover {
    box-shadow: ${({ theme }) => theme.shadow.boxHover};
  }
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.white.default};
  padding: 16px;
  min-height: 130px;
  border-radius: 8px 8px 0 0;

  .subHeading {
    color: ${({ theme }) => theme.colors.text.faded};
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

export const Price = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 12px 10px;

  .account {
    display: block;
    color: ${({ theme }) => theme.colors.text.faded};
    font-size: ${({ theme }) => theme.fontSize.small};
    padding-bottom: 3px;
  }
`;

export const PriceRow = styled.div<IPriceRow>`
  display: ${({ hide = false }) => (hide ? 'none' : 'flex')};
  flex-direction: row;
  margin-bottom: 5px;
  position: relative;
`;

export const Features = styled.div`
  padding-top: 10px;
`;

export const Feature = styled.div`
  .feature {
    margin-left: 5px;
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

export const Details = styled.div`
  padding: 0 16px 16px;
`;

export const BillingDescription = styled.div`
  color: ${({ theme }) => theme.colors.text.faded};
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-left: 8px;
  margin-top: 3px;
  margin-bottom: -4px;
`;

export const Savings = styled(Span)`
  position: absolute;
  right: 0;
  top: -20px;
  color: ${({ theme }) => theme.colors.secondary.default};
  font-weight: 500;
  font-size: 14px;
`;

export const AddOn = styled.div`
  background: ${({ theme }) => theme.colors.default.light};
  border-radius: 0 0 16px 16px;
  padding: 16px;

  .addOnHeading {
    display: block;
    font-size: ${({ theme }) => theme.fontSize.button};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    margin-bottom: 8px;
  }

  .addOnContent {
    font-size: ${({ theme }) => theme.fontSize.small};
    color: ${({ theme }) => theme.colors.text.grey};
  }
`;
