import styled from 'styled-components/macro';
import { Span } from '../../../../../shared/typefaces/Typefaces.styles';
import { device } from '../../../../../../styles/device';

export const Container = styled.div``;

export const Discount = styled(Span)`
  padding: 0px 20px 10px 20px;
  text-align: right;
  width: 100%;
  min-height: 28px;
  display: block;
  color: ${({ theme }) => theme.colors.secondary.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.button};
`;

export const AddOn = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white.default};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.tinyBox};
  margin-bottom: 0;

  @media ${device.tablet} {
    flex-direction: row;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.default.transparent};
  padding: 20px 10px;
  min-width: 250px;
  border-radius: 8px 0 0 8px;

  .title {
    font-size: ${({ theme }) => theme.fontSize.button};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    color: ${({ theme }) => theme.colors.text.grey};
  }

  .icon {
    cursor: pointer;
  }

  @media ${device.tablet} {
    .title {
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      color: ${({ theme }) => theme.colors.text.black};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  width: 100%;

  .content {
    font-size: ${({ theme }) => theme.fontSize.button};
    margin-right: 10px;
  }

  .cta {
    height: 40px;
    width: 40px;
    background-color: ${({ theme }) => theme.colors.white.default};
    color: ${({ theme }) => theme.colors.text.grey};
    margin-left: 20px;

    &:hover {
      background-color: ${({ theme }) => theme.colors.error.default};
      color: ${({ theme }) => theme.colors.text.white};

      .icon {
        fill: ${({ theme }) => theme.colors.white.default};
      }
    }
  }

  @media ${device.mobileL} {
    padding: 10px;
  }
`;
