import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';

interface IBillingCycleProps {
  selected: boolean;
}

export const Container = styled.div``;

export const Header = styled.div`
  .heading {
    width: 100%;
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.heading};
    margin-bottom: 20px;
  }
`;

export const BillingCycle = styled.div<IBillingCycleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  margin: 0 auto 60px;
  background-color: ${({ theme }) => theme.colors.default.light};
  border-radius: 30px;
`;

export const Tab = styled.div<IBillingCycleProps>`
  background-color: ${({ theme, selected }) =>
    selected && theme.colors.secondary.default};
  background-image: ${({ theme, selected }) =>
    selected &&
    `linear-gradient(to right top, ${theme.colors.secondary.dark}, ${theme.colors.secondary.default}, ${theme.colors.secondary.faded})`};
  padding: 10px 20px;
  border-radius: 30px;
  font-size: ${({ theme }) => theme.fontSize.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme, selected }) => selected && theme.colors.white.default};

  &:hover {
    background-color: ${({ theme, selected }) =>
      !selected && theme.colors.default.faded};
    cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
  }
`;

export const Plans = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 20px;
  justify-content: center;

  @media ${device.laptopL} {
    flex-direction: row;
  }

  @media ${device.laptopL} {
    margin: 0;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.mobileM} {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.mobileM} {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
`;

export const ModalForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  @media ${device.mobileM} {
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  }
`;
