import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const Addons = styled.div`
  margin: 20px auto 100px;
  width: 100%;
  max-width: 800px;

  .heading {
    color: ${({ theme }) => theme.colors.text.grey};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.fontSize.default};
    margin-left: 20px;
    margin-bottom: 5px;
  }

  .footer {
    color: ${({ theme }) => theme.colors.text.grey};
    font-weight: ${({ theme }) => theme.fontWeight.default};
    font-size: ${({ theme }) => theme.fontSize.small};
    margin-top: 20px;
    margin-left: 20px;
  }

  @media ${device.laptop} {
    margin: 20px 0 100px;
    max-width: 900px;
  }
`;
