import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AccountType,
  AddOnApp,
  BillingCycle,
} from '../../../../../generated/graphql';
import { Tick } from '../../../icons';
import { H3, Span } from '../../../typefaces/Typefaces.styles';
import * as S from './SelectedPlan.styles';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import {
  addOnsArray,
  capitalizeFirstLetter,
  IPlanOptionProps,
} from '../../../../../utils/helper/strings';
import { currency, isEmpty } from '../../../../../utils/helper';
import { isFreeAccount } from '../../../../../utils/helper/subscription';

export const SelectedPlan: FC<IPlanOptionProps> = ({
  type,
  heading,
  subHeading,
  price,
  billedAnnually,
  savingDescription,
  monthlyPriceDescription,
  priceDescription,
  monthlyPrice,
  features,
}) => {
  const { newAccount, dispatch } = useContext(LayoutContext);
  const { push } = useHistory();
  const yearlyBillingCycle = newAccount?.billingCycle === BillingCycle.Yearly;
  const totalCostOverSubscriptionPeriod = yearlyBillingCycle
    ? currency(Number(price) * 12)
    : currency(Number(monthlyPrice));
  const duration = yearlyBillingCycle ? 'per year' : 'per month';
  const freeAccount = isFreeAccount(newAccount?.type);
  const selectedAddOns = newAccount?.addOns
    ?.filter((a) => a.updateAddOn)
    .map((addOn) => {
      return addOnsArray.find((a) => a.addOnApp === addOn.app);
    })
    .filter((a) => !!a);

  const hadCalculationAddOn = selectedAddOns?.find(
    (a) => a.addOnApp === AddOnApp.Calculator
  );
  const accountIncludesCalculations =
    (newAccount?.type === AccountType.HeadChef ||
      newAccount?.type === AccountType.Owner) &&
    hadCalculationAddOn;

  const totalAddOnsCost = !isEmpty(selectedAddOns)
    ? selectedAddOns
        ?.map((a) =>
          yearlyBillingCycle && type !== AccountType.Registered
            ? Number(a.price)
            : Number(a.monthlyPrice)
        )
        .reduce((accumulator, currentValue) => {
          return accumulator + currentValue;
        }, 0)
    : undefined;

  const handleSelectAddOn = (addOnApp: AddOnApp) => {
    const filteredOutNewAccountAddons = newAccount?.addOns?.filter(
      (a) => a.app !== addOnApp
    );

    dispatch({
      type: 'SET_NEW_ACCOUNT',
      payload: {
        ...newAccount,
        addOns: filteredOutNewAccountAddons,
      },
    });
  };

  return (
    <S.Container>
      <S.Left>
        <H3 className="title">
          <Tick color="secondary" size="medium" className="tick" />
          Selected Plan
        </H3>
        <Span className="paragraph">
          Great choice, you have selected the{' '}
          <S.AccountTypeName type={type}>{heading}</S.AccountTypeName> plan.
          After confirming your account details we will progress you to our
          billing page. Once your payment details are confirmed you will be
          navigated to your recipes.
        </Span>
        <Span className="paragraph">
          Click here if you would like to
          <Span className="highlight" onClick={() => push('select-account')}>
            {' '}
            change plan now{' '}
          </Span>
          this will take you back to the previous page.
        </Span>
        <Span className="terms">
          <Span className="termsHighlight">Total Subscrition Cost: </Span>{' '}
          {freeAccount
            ? price
            : `$
          ${totalCostOverSubscriptionPeriod} ${duration}`}
        </Span>
        {totalAddOnsCost && (
          <Span className="terms">
            <Span className="termsHighlight">Total Add Ons Cost: </Span>$
            {currency(totalAddOnsCost)}
          </Span>
        )}
        <Span className="terms">
          <Span className="termsHighlight">Billing Cycle: </Span>
          {capitalizeFirstLetter(
            type === AccountType.Registered
              ? BillingCycle.Monthly
              : newAccount?.billingCycle
          )}
        </Span>
        {accountIncludesCalculations && (
          <div className="recommendation">
            <Span className="recommendationHeading">Tip **</Span>
            <br />
            <Span className="recommendationContent">
              We recommend removing{' '}
              <Span className="termsHighlight">"Calculate Profit + Costs"</Span>{' '}
              Add On as it's included in your{' '}
              <S.AccountTypeName type={type}>{heading}</S.AccountTypeName> plan
            </Span>
          </div>
        )}
      </S.Left>

      <S.Right>
        <S.Plan type={type}>
          {!isEmpty(newAccount?.addOns) && (
            <S.BaseAccount>Base Account</S.BaseAccount>
          )}
          {yearlyBillingCycle && savingDescription && (
            <S.Savings>{savingDescription}</S.Savings>
          )}
          <S.Header>
            <H3 className="heading">{heading}</H3>
            <Span className="subHeading">{subHeading}</Span>
          </S.Header>
          <S.Price>
            <S.PriceRow>
              <Span className="dollarIcon">$</Span>
              <Span className="price">
                {yearlyBillingCycle ? price : monthlyPrice}
              </Span>
              {yearlyBillingCycle && billedAnnually && (
                <S.BillingDescription>Billed Anually</S.BillingDescription>
              )}
            </S.PriceRow>
            <Span className="priceDescription">
              {yearlyBillingCycle ? priceDescription : monthlyPriceDescription}
            </Span>
          </S.Price>

          <S.Features>
            {features.map((feature, idx) => {
              return (
                <S.Feature key={idx}>
                  <Tick color="secondary" size="tiny" />
                  <Span className="feature">{feature}</Span>
                </S.Feature>
              );
            })}
          </S.Features>
        </S.Plan>
        {selectedAddOns?.length !== 0 &&
          selectedAddOns?.map((a) => {
            return (
              a && (
                <S.Plan key={a.addOnApp!} type={type} className="bottomPlan">
                  {<S.BaseAccount>Add On</S.BaseAccount>}
                  {
                    <S.DeSelectAccount
                      onClick={() => handleSelectAddOn(a.addOnApp!)}
                    >
                      <Span className="cross">+</Span>
                    </S.DeSelectAccount>
                  }
                  {yearlyBillingCycle &&
                    type !== AccountType.Registered &&
                    a.savingDescription && (
                      <S.Savings>{a.savingDescription}</S.Savings>
                    )}
                  <S.Header>
                    <H3 className="heading">{a.heading}</H3>
                    <Span className="subHeading">{a.info}</Span>
                  </S.Header>
                  <S.Price>
                    <S.PriceRow>
                      <Span className="addOnDollarIcon">$</Span>
                      <Span className="addOnPrice">
                        {yearlyBillingCycle && type !== AccountType.Registered
                          ? a.price
                          : a.monthlyPrice}
                      </Span>
                    </S.PriceRow>
                    <Span className="priceDescription">
                      {yearlyBillingCycle && type !== AccountType.Registered
                        ? a.priceDescription
                        : a.monthlyPriceDescription}
                    </Span>
                  </S.Price>

                  <S.Features>
                    {a.features.map((feature, idx) => {
                      return (
                        <S.Feature key={idx}>
                          <Tick color="secondary" size="tiny" />
                          <Span className="feature">{feature}</Span>
                        </S.Feature>
                      );
                    })}
                  </S.Features>
                </S.Plan>
              )
            );
          })}
      </S.Right>
    </S.Container>
  );
};
