import React, { FC, useState } from 'react';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';
import { Card, Content, Header } from '../../../../shared/card';
import { Apple } from '../../../../shared/icons';
import {
  GoalUser,
  GoalUsers,
  HighlightsItem,
  HighlightsRow,
} from './Users.styles';
import { Button } from '../../../../shared/button';
import { IStatsProps } from '../dashboard.types';

export const Users: FC<IStatsProps> = ({ stats }) => {
  const [showGoalUsers, setShowGoalUsers] = useState(false);

  const ctaButtons = (
    <Button
      color="default"
      inversed
      onClick={() => setShowGoalUsers(!showGoalUsers)}
    >
      Goal Users
    </Button>
  );

  return (
    <Card withCardLink>
      <Header
        icon={<Apple size="small" />}
        heading="Users"
        subHeading={'Total Users: ' + stats?.totalUsers}
        button={ctaButtons}
      />
      <Content fullWidth>
        <HighlightsRow>
          <HighlightsItem>
            <Span>{stats?.totalGuestUsers}</Span>
            <Span>Guest Users</Span>
          </HighlightsItem>
          <HighlightsItem>
            <Span>{stats?.totalRegisteredUsers}</Span>
            <Span>Users</Span>
          </HighlightsItem>
          <HighlightsItem>
            <Span>{stats?.totalGoalUsers}</Span>
            <Span>Goal Users</Span>
          </HighlightsItem>
        </HighlightsRow>
        {showGoalUsers && (
          <GoalUsers>
            {stats?.goalUsers &&
              stats?.goalUsers.map((goalUser, index) => (
                <GoalUser key={index}>
                  <Span>
                    {goalUser.firstName + ' ' + goalUser.lastName + ' - '}
                    <Span fontWeight="semibold">recipes:</Span>{' '}
                    {goalUser.totalRecipes}
                  </Span>
                  <br />
                  <Span>
                    {goalUser.email} - {goalUser.phone}
                  </Span>
                </GoalUser>
              ))}
          </GoalUsers>
        )}
      </Content>
    </Card>
  );
};

export default Users;
