import React, { useContext } from 'react';
import * as S from './DashboardUsers.styles';
import { theme } from '../../../../../styles/theme';
import { LayoutPage } from '../../../../shared/layout';
import { Button } from '../../../../shared/button';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { AccountType, useUsersQuery } from '../../../../../generated/graphql';
import { UserRow } from './UserRow';

export const DashboardUsers = () => {
  const { appWidth, dispatch } = useContext(LayoutContext);
  const { data } = useUsersQuery({
    fetchPolicy: 'cache-and-network',
  });
  const users = data?.users.users;
  const threeMonthsInMilliSeconds = 3 * 30 * 24 * 60 * 60 * 1000; // 3 months in milliseconds
  const sortedCurrentUsers = users?.sort(
    (a, b) => parseInt(b.createdAt) - parseInt(a.createdAt)
  );

  const feedbackUsers = sortedCurrentUsers?.filter(
    (u) => u.accountType === AccountType.Feedback
  );
  const currentUsers = sortedCurrentUsers?.filter(
    (u) =>
      !u.email.includes('guestuser') &&
      u.lastLoginAt !== null &&
      parseInt(u.lastLoginAt) >= Date.now() - threeMonthsInMilliSeconds
  );

  const staleUsers = sortedCurrentUsers?.filter(
    (u) =>
      !u.email.includes('guestuser') && // Exclude guest users
      (u.lastLoginAt === null ||
        parseInt(u.lastLoginAt) < Date.now() - threeMonthsInMilliSeconds) // Last login is null or more than 3 months ago
  );

  const closeSlider = () => {
    dispatch({ type: 'HIDE_TOOL_TIP' });
    dispatch({ type: 'TOGGLE_SLIDER' });
  };

  const addFeedBackUser = () => {
    dispatch({ type: 'EXTRA_SLIDER_PAGE', payload: 'feedbackUser' });
  };

  return (
    <LayoutPage
      backButton
      backButtonCTA={closeSlider}
      withLine
      heading="All Users"
      subHeading="Below are all your users across the whole platform."
      slider
      actionArray={
        appWidth !== 0 && appWidth < theme.mQ.tablet
          ? [
              <Button color="default" asCircle inversed onClick={closeSlider}>
                x
              </Button>,
              <Button color="secondary" onClick={addFeedBackUser}>
                Add User
              </Button>,
            ]
          : [
              <Button color="default" inversed onClick={closeSlider}>
                Cancel
              </Button>,
              <Button color="secondary" onClick={addFeedBackUser}>
                Add Feedback User
              </Button>,
            ]
      }
    >
      <S.Container>
        {feedbackUsers && (
          <S.UsersContainer withCardLink>
            <S.UsersHeader>Feedback Users</S.UsersHeader>
            <S.Users>
              {feedbackUsers?.map((u) => {
                return <UserRow user={u} />;
              })}
            </S.Users>
          </S.UsersContainer>
        )}
        {currentUsers && (
          <S.UsersContainer withCardLink>
            <S.UsersHeader>Current Users</S.UsersHeader>
            <S.Users>
              {currentUsers?.map((u) => {
                return <UserRow user={u} />;
              })}
            </S.Users>
          </S.UsersContainer>
        )}
        {staleUsers && (
          <S.UsersContainer>
            <S.UsersHeader>Stale Users</S.UsersHeader>
            <S.Users>
              {staleUsers?.map((u) => {
                return <UserRow user={u} />;
              })}
            </S.Users>
          </S.UsersContainer>
        )}
      </S.Container>
    </LayoutPage>
  );
};
