import styled from 'styled-components/macro';
import { device } from '../../../styles/device';
import { H1 } from '../../shared/typefaces/Typefaces.styles';

interface IMainProps {
  marginTop?: 'xlarge' | 'huge';
}

interface IBillingCycleProps {
  selected: boolean;
}

export const Container = styled.section`
  position: relative;

  .questionsHeader {
    text-align: center;
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: ${({ theme }) => theme.fontSize.heading};
    margin-bottom: 50px;
  }
`;

export const Main = styled.section<IMainProps>`
  position: absolute;
  top: ${({ marginTop }) => (marginTop === 'huge' ? 630 : 500)}px;
  width: 100%;
  overflow: hidden;

  @media ${device.mobileS} {
    top: ${({ marginTop }) => (marginTop === 'huge' ? 680 : 500)}px;
  }
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @media ${device.laptopL} {
    width: 900px;
  }
`;

export const Header = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin: 30px 10px 80px;
  color: ${({ theme }) => theme.colors.white.default};

  .subHeading {
    display: block;
    margin-bottom: 5px;
    margin-right: 10px;
    width: 100%;

    .subHeadingTop {
      color: ${({ theme }) => theme.colors.white.default};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      font-size: ${({ theme }) => theme.fontSize.largeHeading};
      line-height: ${({ theme }) => theme.lineHeight.title};
      margin-right: 5px;
    }
    .subHeadingBottom {
      color: ${({ theme }) => theme.colors.white.default};
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      font-size: ${({ theme }) => theme.fontSize.largeHeading};
      line-height: ${({ theme }) => theme.lineHeight.title};
    }
  }

  @media ${device.mobileM} {
    margin: 0 10px 120px;
  }

  @media ${device.tablet} {
    margin: 0 10px 180px;
    .subHeading {
      display: flex;
      flex-direction: column;
    }
  }

  @media ${device.laptopL} {
    width: 900px;
    margin: 0 auto 180px;
  }
`;

export const AccountHeader = styled.div`
  margin: 0 20px;

  .accountHeading {
    color: ${({ theme }) => theme.colors.black};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-size: ${({ theme }) => theme.fontSize.heading};
    text-align: center;
  }

  .accountSubHeading {
    display: block;
    width: 100%;
    color: ${({ theme }) => theme.colors.black};
    font-weight: ${({ theme }) => theme.fontWeight.normal};
    font-size: ${({ theme }) => theme.fontSize.default};
    text-align: center;
    margin-top: 10px;
    margin-bottom: 60px;
  }

  @media ${device.mobileM} {
    .accountHeading,
    .accountSubHeading {
      text-align: left;
    }
  }

  @media ${device.tablet} {
    .accountSubHeading {
      margin-top: 0px;
    }
  }
  @media ${device.laptop} {
    .accountHeading {
      margin-left: 20px;
      margin-bottom: 5px;
    }

    .accountSubHeading {
      margin-top: 0px;
      margin-left: 20px;
    }
  }

  @media ${device.laptop} {
    margin: 60px 0 0;
  }
`;

export const Heading = styled(H1)`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 5px 0 30px !important;
  max-width: 450px;
  text-align: left;
  color: ${({ theme }) => theme.colors.white.faded};
  text-shadow: ${({ theme }) => theme.shadow.text};
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  .backButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: ${({ theme }) => theme.colors.white.light};
    margin-right: 5px;
    cursor: pointer;

    .backArrow {
      fill: ${({ theme }) => theme.colors.white.default};
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors.white.default};

      .backArrow {
        fill: ${({ theme }) => theme.colors.text.grey};
      }
    }
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Logo = styled.img`
  display: none !important;

  @media ${device.mobileM} {
    display: block !important;
    width: 80px;
    height: 80px;
    margin-left: 30px;
  }
`;

export const Callout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;

  .heading {
    font-size: ${({ theme }) => theme.fontSize.medium};
    margin: 0 20px;
  }
`;

export const BreakLine = styled.div`
  height: 1px;
  width: 60%;
  background-color: ${({ theme }) => theme.colors.primary.default};
  margin: 20px 0;
`;

export const BillingCycle = styled.div<IBillingCycleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors.default.light};
  border-radius: 30px;
`;

export const Tab = styled.div<IBillingCycleProps>`
  background-color: ${({ theme, selected }) =>
    selected && theme.colors.secondary.default};
  background-image: ${({ theme, selected }) =>
    selected &&
    `linear-gradient(to right top, ${theme.colors.secondary.dark}, ${theme.colors.secondary.default}, ${theme.colors.secondary.faded})`};
  padding: 10px 20px;
  border-radius: 30px;
  font-size: ${({ theme }) => theme.fontSize.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme, selected }) => selected && theme.colors.white.default};

  &:hover {
    background-color: ${({ theme, selected }) =>
      !selected && theme.colors.default.faded};
    cursor: ${({ selected }) => (selected ? 'default' : 'pointer')};
  }
`;

export const YearlyBillingMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px auto;
  height: 60px;
  border-radius: 30px;
  background-color: ${({ theme }) => theme.colors.default.transparent};
  cursor: pointer;

  .upgradeAccount {
    max-width: 400px;
  }

  .message {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .center {
    text-align: center;
    width: 100%;
    color: ${({ theme }) => theme.colors.text.white};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    line-height: ${({ theme }) => theme.lineHeight.medium};
  }
`;

export const AddOns = styled.div``;

export const FooterImage = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 60%;
  margin: 0 auto 200px;

  .footerImage {
    border-radius: 20px;
  }
`;
