import React, { FC, useContext } from 'react';
import * as S from './SelectedAddOn.styles';
import { addOnsArray } from '../../../../../../utils/helper/strings';
import {
  INewAccountAddOnProps,
  LayoutContext,
} from '../../../../../../contexts/layoutContext';
import { AddOnApp, BillingCycle } from '../../../../../../generated/graphql';
import { IInfoModalProps } from '../SelectedAddOns';
import { H4, Span } from '../../../../../shared/typefaces/Typefaces.styles';
import { Bin, Info } from '../../../../../shared/icons';
import { Button } from '../../../../../shared/button';

interface IAddOnProps {
  addOn: INewAccountAddOnProps;
  setInfoModal: React.Dispatch<React.SetStateAction<IInfoModalProps>>;
}

export const SelectedAddOn: FC<IAddOnProps> = ({ addOn, setInfoModal }) => {
  const { newAccount, dispatch } = useContext(LayoutContext);
  const addOnDetails = addOnsArray.find((a) => a.addOnApp === addOn.app);
  if (!addOnDetails) return null;

  const {
    heading,
    subHeading,
    info,
    monthlyPrice,
    monthlyPriceDescription,
    price,
    priceDescription,
    savingDescription,
    addOnApp,
  } = addOnDetails;

  const handleInfoClick = () => {
    setInfoModal && setInfoModal({ show: true, heading, content: info });
  };

  const handleRemoveAddOn = (addOnApp: AddOnApp) => {
    const filteredOutNewAccountAddons = newAccount?.addOns?.filter(
      (a) => a.app !== addOnApp
    );

    dispatch({
      type: 'SET_NEW_ACCOUNT',
      payload: {
        ...newAccount,
        addOns: filteredOutNewAccountAddons,
      },
    });
  };

  const showDiscount = newAccount?.billingCycle === BillingCycle.Yearly;
  const pricing =
    newAccount?.billingCycle === BillingCycle.Monthly
      ? `$${monthlyPrice} for ${monthlyPriceDescription}`
      : `$${price} for ${priceDescription}`;

  return (
    <S.Container>
      <S.Discount color="secondary" fontSize="small">
        {showDiscount && savingDescription}
      </S.Discount>
      <S.AddOn>
        <S.Header>
          <H4 className="title">{heading}</H4>
          <Info
            size="small"
            color="grey"
            faded
            className="icon"
            onClick={handleInfoClick}
          />
        </S.Header>
        <S.Content>
          <Span className="content">{`${subHeading} ${pricing}`}</Span>
          <Button
            onClick={() => handleRemoveAddOn(addOnApp)}
            size="small"
            className="cta"
            asCircle
          >
            <Bin color="default" size="small" className="icon" />
          </Button>
        </S.Content>
      </S.AddOn>
    </S.Container>
  );
};
