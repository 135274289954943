import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';

interface IHeaderProps {
  footer?: boolean;
}

export const Container = styled.div``;

export const Header = styled.div<IHeaderProps>`
  display: flex;
  flex-direction: column-reverse;
  align-items: ${({ footer }) => (footer ? 'flex-end' : 'flex-end')};

  @media ${device.tablet} {
    flex-direction: row;
    align-items: ${({ footer }) => (footer ? 'flex-end' : 'flex-start')};
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 60px;

  .title {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: ${({ theme }) => theme.fontSize.large};
    margin-bottom: 15px;
  }

  .info {
    line-height: ${({ theme }) => theme.lineHeight.medium};
    color: ${({ theme }) => theme.colors.text.faded};
  }

  .CTA {
    min-width: 120px;
    max-width: 120px;
    margin-top: 30px;
  }
`;

export const Right = styled.div<IHeaderProps>`
  display: flex;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.colors.default.transparent};
  border-radius: ${({ footer }) =>
    footer ? `200px 0px 0px 200px` : `0 8px 0px 200px`};

  @media ${device.mobileS} {
    min-width: 320px;
  }

  @media ${device.tablet} {
    border-radius: ${({ footer }) =>
      footer ? `200px 0px 8px 0px` : `0 8px 0px 200px`};
  }
`;

export const RightContent = styled.div<IHeaderProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: ${({ footer }) => (footer ? '30px 60px 0px' : '30px 60px 10px')};

  .alignRight {
    display: flex;
    justify-content: flex-end;
  }

  .dollarIcon {
    vertical-align: super;
    font-size: ${({ theme }) => theme.fontSize.default};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.text.grey};
    padding-right: 3px;
  }

  .price {
    font-size: ${({ theme }) => theme.fontSize.heading};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.colors.text.grey};
    margin-bottom: 20px;
  }

  .priceDescription {
    font-size: ${({ theme }) => theme.fontSize.button};
    color: ${({ theme }) => theme.colors.text.grey};
  }

  .valid {
    font-size: ${({ theme }) => theme.fontSize.button};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.text.grey};
    margin-top: 20px;
    margin-bottom: 60px;
  }

  .CTA {
    margin: 30px 0;
    min-width: 100px;
  }
`;
