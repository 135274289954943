import styled from 'styled-components/macro';
import { AccountType } from '../../../../../generated/graphql';
import { Span } from '../../../typefaces/Typefaces.styles';
import { device } from '../../../../../styles/device';

export interface ITypeProps {
  type: AccountType;
}

interface IPriceRow {
  hide?: boolean;
}
export const getAccountTypeColor = (theme: any, type?: AccountType) => {
  switch (type) {
    case AccountType.Registered:
      return theme.colors.blue;
    case AccountType.RecipePlus:
      return theme.colors.secondary.default;
    case AccountType.HeadChef:
      return theme.colors.primary.default;
    case AccountType.Owner:
      return theme.colors.purple;
    default:
      return theme.colors.text.default;
  }
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.default.lightest};
  border-radius: 8px;
  padding: 40px;
  max-width: 400px;
  margin: 0 auto;

  @media ${device.tablet} {
    flex-direction: row;
    max-width: 900px;
  }
`;

export const Left = styled.div`
  margin-bottom: 40px;

  .tick {
    margin-right: 10px;
  }

  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: ${({ theme }) => theme.fontSize.large};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.text.black};
    margin-bottom: 20px;
  }

  .paragraph {
    display: block;
    margin-bottom: 20px;
    line-height: ${({ theme }) => theme.lineHeight.medium};
  }

  .highlight {
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    color: ${({ theme }) => theme.colors.text.grey};
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.text.black};
    }
  }

  .terms {
    display: block;
    margin-bottom: 8px;
  }

  .termsHighlight {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.text.grey};
    margin-right: 5px;
  }

  .recommendation {
    border: 1px solid ${({ theme }) => theme.colors.error.default};
    border-radius: 8px;
    margin-top: 30px;
    padding: 10px;
    box-shadow: -2px 2px 2px 2px rgba(255, 1, 1, 0.2);

    .recommendationHeading {
      font-size: ${({ theme }) => theme.fontSize.default};
      font-weight: ${({ theme }) => theme.fontWeight.semibold};
    }
    .recommendationContent {
      display: block;
      font-size: ${({ theme }) => theme.fontSize.button};
      line-height: ${({ theme }) => theme.lineHeight.medium};
      padding-top: 5px;
    }
  }

  @media ${device.tablet} {
    margin-bottom: 0;
    max-width: 50%;
  }
`;

export const Right = styled.div`
  .bottomPlan {
    margin-top: 40px;
  }
`;

export const Plan = styled.div<ITypeProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  margin: 10px;
  background-color: ${({ theme }) => theme.colors.default.transparent};
  box-shadow: ${({ theme }) => theme.shadow.box};
  border-radius: 8px;

  .heading {
    color: ${({ type, theme }) => getAccountTypeColor(theme, type)};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    margin: -10px 0 0;
  }

  .dollarIcon,
  .addOnDollarIcon {
    display: block;
    position: absolute;
    top: -5px;
    font-size: ${({ theme }) => theme.fontSize.small};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.text.grey};
  }

  .dollarIcon {
    display: ${({ type }) =>
      type === AccountType.Registered ? 'none' : 'block'};
  }

  .price,
  .addOnPrice {
    font-size: ${({ theme }) => theme.fontSize.large};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.colors.text.grey};
    margin-left: 10px;
  }

  .price {
    margin-left: ${({ type }) =>
      type === AccountType.Registered ? '0' : '10px'};
  }

  .priceDescription {
    font-size: ${({ theme }) => theme.fontSize.small};
  }

  .CTA {
    margin: 10px auto;
  }
`;

export const Header = styled.div`
  background-color: ${({ theme }) => theme.colors.white.default};
  padding: 10px 10px 30px 10px;
  border-radius: 8px 8px 0 0;

  .subHeading {
    color: ${({ theme }) => theme.colors.text.faded};
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

export const Price = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const PriceRow = styled.div<IPriceRow>`
  display: ${({ hide = false }) => (hide ? 'none' : 'flex')};
  flex-direction: row;
  margin-bottom: 5px;
  position: relative;
`;

export const Features = styled.div`
  padding: 10px;
`;

export const Feature = styled.div`
  .feature {
    margin-left: 5px;
    font-size: ${({ theme }) => theme.fontSize.small};
  }
`;

export const AccountTypeName = styled(Span)<ITypeProps>`
  color: ${({ type, theme }) => getAccountTypeColor(theme, type)};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;

export const BillingDescription = styled.div`
  color: ${({ theme }) => theme.colors.text.faded};
  font-size: ${({ theme }) => theme.fontSize.small};
  margin-left: 8px;
  margin-top: 3px;
  margin-bottom: -4px;
`;

export const BaseAccount = styled(Span)`
  position: absolute;
  left: 0;
  top: -20px;
  color: ${({ theme }) => theme.colors.text.faded};
  font-weight: 500;
  font-size: 14px;
`;

export const Savings = styled(Span)`
  position: absolute;
  right: 0;
  top: -20px;
  color: ${({ theme }) => theme.colors.secondary.default};
  font-weight: 500;
  font-size: 14px;
`;

export const DeSelectAccount = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.text.faded};
  font-weight: 500;
  font-size: 14px;
  border: 1px solid transparent;
  border-radius: 20px;
  margin: 0;

  .cross {
    transform-origin: center;
    transform: rotate(45deg);
    font-size: ${({ theme }) => theme.fontSize.heading};
    color: ${({ theme }) => theme.colors.text.faded};
    margin: 5px;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid ${({ theme }) => theme.colors.grey.faded};

    .cross {
      color: ${({ theme }) => theme.colors.text.default};
    }
  }
`;
