import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';
import { LayoutColumnContainer } from '../../../../shared/layout/layoutColumn/LayoutColumn.styles';
import { Button } from '../../../../shared/button';
import { IRowProps } from './users.types';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';

export const HighlightsRow = styled.ul`
  display: flex;
  justify-content: space-between;
  max-width: 480px;
  margin: 15px !important;

  @media ${device.mobileM} {
    width: 480px;
    margin: 15px auto !important;
  }
`;

export const HighlightsItem = styled.li`
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${Span} {
    display: block;
    text-align: center;

    &:first-child {
      font-size: ${({ theme }) => theme.fontSize.large};
      color: ${({ theme }) => theme.colors.text.default};
      padding: 15px 0;
    }
    &:last-child {
      font-size: ${({ theme }) => theme.fontSize.small};
      color: ${({ theme }) => theme.colors.text.faded};
    }
  }
`;

export const Table = styled.section`
  margin-top: 30px;
`;

export const Col = styled.li`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 5px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};

  &:first-child {
    justify-content: flex-start;
    margin-left: 15px;
    padding-left: 0;
  }
  &:last-child {
    padding-right: 15px;
  }

  @media ${device.laptop} {
    &:first-child {
      justify-content: center;
      border: 0;
      margin: 0;
    }
    &:nth-child(2) {
      justify-content: flex-start;
      padding-left: 0;
    }
  }
`;

export const Row = styled.ul<IRowProps>`
  min-height: 55px;
  display: grid;
  grid-template-columns: ${({ updateIngredient }) =>
    updateIngredient ? 'auto 130px 50px' : ' auto 100px 50px'};

  &:last-child {
    ${Col} {
      border: 0;
    }
  }

  &:hover {
    cursor: pointer;
  }

  ${LayoutColumnContainer} {
    padding: 0;
    grid-template-columns: auto;
    grid-gap: 15px;
    .last {
      text-align: right;
    }
  }

  @media ${device.mobileM} {
    grid-template-columns: ${({ updateIngredient }) =>
      updateIngredient ? 'auto 210px 50px' : 'auto 100px 80px 50px'};
  }
  @media ${device.mobileL} {
    grid-template-columns: ${({ updateIngredient }) =>
      updateIngredient ? 'auto 300px 50px' : 'auto 60px 125px 80px 50px'};
    ${LayoutColumnContainer} {
      padding: 0;
      grid-template-columns: auto 2px 50px;
      grid-gap: 15px;
      .last {
        text-align: right;
      }
    }
  }
  @media ${device.tablet} {
    grid-template-columns: ${({ updateIngredient }) =>
      updateIngredient ? 'auto 300px 50px' : 'auto 150px 160px 80px 50px'};
  }
  @media ${device.laptop} {
    grid-template-columns: ${({ updateIngredient }) =>
      updateIngredient
        ? '70px auto 300px 90px'
        : '70px auto 170px 180px 100px 70px'};
  }
`;

export const HeaderRow = styled.ul`
  min-height: 40px;
  background-color: ${({ theme }) => theme.colors.background};
  display: grid;
  grid-template-columns: auto 90px 50px;
  ${Col} {
    border: 0;
  }

  @media ${device.mobileM} {
    grid-template-columns: auto 105px 130px;
  }
  @media ${device.mobileL} {
    grid-template-columns: auto 105px 125px 130px;
  }
  @media ${device.tablet} {
    grid-template-columns: auto 150px 160px 130px;
  }
  @media ${device.laptop} {
    grid-template-columns: 70px auto 170px 180px 170px;
  }
`;

export const CTAButton = styled(Button)`
  min-width: 182px;
`;

export const Icon = styled.div`
  height: 50px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:hover {
    cursor: pointer;
    svg {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const GoalUsers = styled.div`
  margin-top: 20px;
  padding: 10px 0 20px;
  margin-left: 75px;
  border-top: 1px solid ${({ theme }) => theme.colors.default.default};
`;

export const GoalUser = styled.div`
  margin-top: 10px;
`;
