import React, { FC } from 'react';
import { AccountType } from '../../../../../generated/graphql';
import {
  advanced,
  calculations,
  essentials,
  owner,
  plans,
  usage,
} from '../../../../../utils/helper/strings';
import { IInfoModalProps } from '../../SelectAccount';
import { PlanDetails } from '../planDetails';
import { Container, Header, Table } from './Pricing.styles';

interface IPricingProps {
  isLoading: boolean;
  onSubmit: (accountType: AccountType) => void;
  setInfoModal: React.Dispatch<React.SetStateAction<IInfoModalProps>>;
}
export interface IPlanProps {
  id: string;
  heading: string;
  price: string;
  subHeading: string;
  text: string;
  type: AccountType;
}

export interface IDetailsProps {
  label: string;
  freeStarter: string | boolean;
  recipePlus: string | boolean;
  headChef: string | boolean;
  owner: string | boolean;
  info: string;
}

export const Pricing: FC<IPricingProps> = ({
  isLoading,
  onSubmit,
  setInfoModal,
}) => {
  return (
    <Container>
      <Header />
      <Table>
        <PlanDetails
          usage={usage}
          essentials={essentials}
          calculations={calculations}
          advanced={advanced}
          owner={owner}
          addUsageLineBreaks={1}
          addCalculationsLineBreaks={1}
          showHeadings
          setInfoModal={setInfoModal}
          onSubmit={onSubmit}
        />
        <PlanDetails
          details={plans.freeStarter}
          usage={usage}
          essentials={essentials}
          calculations={calculations}
          advanced={advanced}
          owner={owner}
          addUsageLineBreaks={1}
          addCalculationsLineBreaks={1}
          isLoading={isLoading}
          setInfoModal={setInfoModal}
          onSubmit={onSubmit}
        />
        <PlanDetails
          details={plans.recipePlus}
          usage={usage}
          essentials={essentials}
          calculations={calculations}
          advanced={advanced}
          owner={owner}
          addUsageLineBreaks={1}
          addCalculationsLineBreaks={1}
          isLoading={isLoading}
          setInfoModal={setInfoModal}
          onSubmit={onSubmit}
        />
        <PlanDetails
          details={plans.headChef}
          usage={usage}
          essentials={essentials}
          calculations={calculations}
          advanced={advanced}
          owner={owner}
          addUsageLineBreaks={1}
          isLoading={isLoading}
          setInfoModal={setInfoModal}
          onSubmit={onSubmit}
        />
        <PlanDetails
          details={plans.owner}
          usage={usage}
          essentials={essentials}
          calculations={calculations}
          advanced={advanced}
          owner={owner}
          addUsageLineBreaks={1}
          isLoading={isLoading}
          setInfoModal={setInfoModal}
          onSubmit={onSubmit}
        />
      </Table>
    </Container>
  );
};
