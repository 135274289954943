import React, { useContext } from 'react';
import { LayoutContext } from '../../../contexts/layoutContext';
import { useInvoicesQuery } from '../../../generated/graphql';
import { theme } from '../../../styles/theme';
import { Button } from '../../shared/button';
import { Card, Content, Header } from '../../shared/card';
import { Email } from '../../shared/icons';
import { LayoutPage } from '../../shared/layout';
import { DotsLoading } from '../../shared/loading';
import { Container, NoInvoicesContainer, Table } from './Invoices.styles';
import InvoiceItem from './sections/InvoiceItem';
import TableHeader from './sections/TableHeader';

const Invoices = () => {
  const { appWidth, dispatch } = useContext(LayoutContext);

  const closeOverlay = () => {
    dispatch({ type: 'TOGGLE_SLIDER' });
  };

  const { data, loading } = useInvoicesQuery();
  const invoices = data?.invoices.invoices ?? [];

  return (
    <LayoutPage
      backButton
      align="center"
      withLine
      heading="Invoices"
      subHeading="All invoices related to your account are listed below."
      slider
      backButtonCTA={closeOverlay}
      actionArray={
        appWidth !== 0 && appWidth < theme.mQ.tablet
          ? [
              <Button
                color="default"
                asCircle
                inversed
                onClick={() => dispatch({ type: 'TOGGLE_SLIDER' })}
              >
                x
              </Button>,
            ]
          : [
              <Button
                color="default"
                inversed
                onClick={() => dispatch({ type: 'TOGGLE_SLIDER' })}
              >
                Cancel
              </Button>,
            ]
      }
    >
      <Container>
        <Card>
          <Header
            icon={<Email size="small" />}
            heading="Invoices"
            subHeading="All invoices related to your account are listed below."
          />
          <Content fullWidth>
            <Table>
              <TableHeader />

              {loading ? (
                <DotsLoading
                  isLoading
                  size="large"
                  lineHeight={10}
                  color="default"
                />
              ) : !invoices.length ? (
                <NoInvoicesContainer>No Invoices found</NoInvoicesContainer>
              ) : (
                invoices.map((invoice, idx) => (
                  <InvoiceItem key={idx} invoice={invoice} />
                ))
              )}
            </Table>
          </Content>
        </Card>
      </Container>
    </LayoutPage>
  );
};

export default Invoices;
