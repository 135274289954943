import { IGetStatsProps } from '../../components/pages/dashboard/sections/dashboard.types';
import {
  AccountType,
  IngredientConfirmationState,
} from '../../generated/graphql';
import { isEmpty } from './objects';

interface IGoalUsersProps {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  totalRecipes: number;
}

interface IStatsProps {
  goalUsers: IGoalUsersProps[];
  totalUsers: number;
  totalGuestUsers: number;
  totalRegisteredUsers: number;
  totalGoalUsers: number;
  totalMonthlySignUps: number;
  totalRecipeRevenue: number;
  totalRecipeProfit: number;
  totalRecipes: number;
  totalMonthlyRecipes: number;
  totalMonthlyActiveUsers: number;
  avgVenueRecipes: number;
  venueOfTheMonth: {
    venueId: string;
    displayName: string;
    email: string;
    phone: string;
    internationalPhone: string;
    address: string;
    recipeCount: number;
    creator: {
      userId: string;
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
      internationalPhone: string;
      position: string;
      accountType: string;
      createdAt: string;
      updatedAt: string;
    };
  };
  totalBrands: number;
  totalSuppliers: number;
  totalIngredients: number;
  totalNewIngredients: number;
}

export const getUserStats = ({
  users,
  venues,
  brands,
  suppliers,
  ingredients,
}: IGetStatsProps) => {
  const stats: IStatsProps = {
    goalUsers: [],
    totalUsers: 0,
    totalGuestUsers: 0,
    totalRegisteredUsers: 0,
    totalGoalUsers: 0,
    totalMonthlySignUps: 0,
    totalRecipeRevenue: 0,
    totalRecipeProfit: 0,
    totalRecipes: 0,
    totalMonthlyRecipes: 0,
    totalMonthlyActiveUsers: 0,
    avgVenueRecipes: 0,
    venueOfTheMonth: {
      venueId: '',
      displayName: '',
      email: '',
      phone: '',
      internationalPhone: '',
      address: '',
      recipeCount: 0,
      creator: {
        userId: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        internationalPhone: '',
        position: '',
        accountType: '',
        createdAt: '',
        updatedAt: '',
      },
    },
    totalBrands: 0,
    totalSuppliers: 0,
    totalIngredients: 0,
    totalNewIngredients: 0,
  };

  var today = new Date();
  var priorTimestamp30DaysAgo = new Date().setDate(today.getDate() - 30);

  if (!isEmpty(users?.data?.users.users)) {
    users?.data?.users.users!.forEach((user) => {
      if (priorTimestamp30DaysAgo < Number(user.createdAt)) {
        stats.totalMonthlySignUps = ++stats.totalMonthlySignUps;
      }
      if (priorTimestamp30DaysAgo < Number(user.lastLoginAt)) {
        stats.totalMonthlyActiveUsers = ++stats.totalMonthlyActiveUsers;
      }
      if (user.accountType === AccountType.Guest) {
        stats.totalGuestUsers = ++stats.totalGuestUsers;
      }
      if (user.accountType === AccountType.Registered) {
        stats.totalRegisteredUsers = ++stats.totalRegisteredUsers;
      }
      if (user.recipes.length > 2) {
        stats.totalGoalUsers = ++stats.totalGoalUsers;
        stats.goalUsers.push({
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone || '',
          totalRecipes: user.recipes.length,
        });
      }
    });
    stats.totalUsers = users?.data?.users.users!.length || 0;
  }

  const recipesPerVenue: number[] = [];

  if (!isEmpty(venues?.data?.venueStats.venues)) {
    venues?.data?.venueStats.venues?.forEach((venue) => {
      venue.recipes.forEach((recipe) => {
        if (priorTimestamp30DaysAgo < Number(recipe.createdAt)) {
          stats.totalMonthlyRecipes = ++stats.totalMonthlyRecipes;
        }
        stats.totalRecipes = ++stats.totalRecipes;
        if (
          recipe.recipeRevenue &&
          recipe.recipeProfit &&
          recipe.recipeProfit >= 0
        ) {
          stats.totalRecipeRevenue =
            stats.totalRecipeRevenue + recipe.recipeRevenue;
          stats.totalRecipeProfit =
            stats.totalRecipeProfit + recipe.recipeProfit;
        }
      });
      if (stats.venueOfTheMonth.recipeCount < venue.recipes.length) {
        stats.venueOfTheMonth.venueId = venue.id;
        stats.venueOfTheMonth.displayName = venue.displayName;
        stats.venueOfTheMonth.email = venue.email;
        stats.venueOfTheMonth.phone = venue.phone || '';
        stats.venueOfTheMonth.internationalPhone =
          venue.internationalPhone || '';
        stats.venueOfTheMonth.address = venue.address?.addressLookup || '';
        stats.venueOfTheMonth.creator.userId = venue.creatorId;
        stats.venueOfTheMonth.recipeCount = venue.recipes.length;
      }
      if (venue.recipes.length > 1) {
        recipesPerVenue.push(venue.recipes.length);
      }
    });
  }

  if (!isEmpty(recipesPerVenue)) {
    stats.avgVenueRecipes =
      recipesPerVenue.reduce((a, b) => a + b, 0) / recipesPerVenue.length;
  }

  if (stats.venueOfTheMonth.recipeCount > 1) {
    const userMatch = users?.data?.users.users?.find(
      (user) => user.id === stats.venueOfTheMonth.creator.userId
    );
    if (userMatch) {
      const {
        firstName,
        lastName,
        email,
        phone,
        internationalPhone,
        position,
        accountType,
        createdAt,
        updatedAt,
      } = userMatch;
      stats.venueOfTheMonth.creator = {
        ...stats.venueOfTheMonth.creator,
        firstName,
        lastName,
        email,
        phone: phone || '',
        internationalPhone: internationalPhone || '',
        position,
        accountType,
        createdAt,
        updatedAt,
      };
    }
  }

  if (!isEmpty(brands?.data?.brands.brands)) {
    stats.totalBrands = brands?.data?.brands.brands!.length || 0;
  }
  if (!isEmpty(suppliers?.data?.suppliers.suppliers)) {
    stats.totalSuppliers = suppliers?.data?.suppliers.suppliers!.length || 0;
  }
  if (!isEmpty(ingredients?.data?.ingredients.ingredients)) {
    stats.totalNewIngredients =
      ingredients!.data!.ingredients.ingredients?.filter(
        (ingredient) =>
          ingredient.confirmationState ===
            IngredientConfirmationState.Unverified && ingredient.active
      ).length || 0;
    stats.totalIngredients =
      ingredients!.data!.ingredients.ingredients?.length || 0;
  }
  return stats;
};
