import React, { FC, useContext } from 'react';
import { Button } from '../../../../shared/button';
import { Info } from '../../../../shared/icons';
import { H3, Span } from '../../../../shared/typefaces/Typefaces.styles';
import { IInfoModalProps } from '../../SelectAccount';
import * as S from './AddOn.styles';
import { IAddOnOptionProps } from '../../../../../utils/helper/strings';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { BillingCycle } from '../../../../../generated/graphql';
import { useHistory } from 'react-router-dom';
import {
  formatDateFromTimestamp,
  validEndDate,
} from '../../../../../utils/helper';
import { isFreeAccount } from '../../../../../utils/helper/subscription';

interface IAddOnProps {
  addOn: IAddOnOptionProps;
  isAccountPage?: boolean;
  setInfoModal: React.Dispatch<React.SetStateAction<IInfoModalProps>>;
}

export const AddOn: FC<IAddOnProps> = ({
  addOn: {
    heading,
    subHeading,
    info,
    monthlyPrice,
    monthlyPriceDescription,
    price,
    priceDescription,
    savingDescription,
    addOnApp,
  },
  setInfoModal,
  isAccountPage,
}) => {
  const { account, newAccount, dispatch } = useContext(LayoutContext);
  const freeAccount = isFreeAccount(newAccount?.type);
  const { push } = useHistory();
  const handleInfoClick = () => {
    setInfoModal && setInfoModal({ show: true, heading, content: info });
  };

  const handleCTAClick = () => {
    if (isAccountPage) {
      dispatch({ type: 'EXTRA_SLIDER_PAGE', payload: 'addOnPage' });
    } else {
      push('/select-account/addOns');
    }
  };

  const showDiscount =
    !freeAccount && newAccount?.billingCycle === BillingCycle.Yearly;
  const pricing =
    newAccount?.billingCycle === BillingCycle.Monthly || freeAccount
      ? `$${monthlyPrice} for ${monthlyPriceDescription}`
      : `$${price} for ${priceDescription}`;

  const accountAddOn = account?.addOns?.find((a) => a.app === addOnApp);
  const { endDateIsValid } = validEndDate(accountAddOn?.endDate);
  const endDate =
    endDateIsValid && formatDateFromTimestamp(accountAddOn!.endDate);
  const added = isAccountPage
    ? endDateIsValid
    : newAccount?.addOns?.find((a) => a.app === addOnApp && a.updateAddOn);

  return (
    <S.Container>
      <S.Discount color="secondary" fontSize="small">
        {showDiscount && savingDescription}
      </S.Discount>
      <S.AddOn onClick={handleCTAClick}>
        <S.Header>
          <H3 className="title">{heading}</H3>
          <Info
            size="small"
            color="grey"
            faded
            className="icon"
            onClick={handleInfoClick}
          />
        </S.Header>
        <S.Content>
          <div>
            <Span className="content">{`${subHeading} ${pricing}`}</Span>
            {endDate && (
              <Span className="valid">{`Valid Until: ${endDate}`}</Span>
            )}
          </div>
          <Button
            onClick={handleCTAClick}
            color="secondary"
            size="small"
            className="cta"
          >
            {added ? 'Added' : 'See More'}
          </Button>
        </S.Content>
      </S.AddOn>
    </S.Container>
  );
};
