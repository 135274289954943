import React, { useContext, useState } from 'react';
import * as S from './AddFeedbackUser.styles';
import * as Yup from 'yup';
import { theme } from '../../../../../styles/theme';
import { LayoutColumn, LayoutPage } from '../../../../shared/layout';
import { LayoutContext } from '../../../../../contexts/layoutContext';
import { Button } from '../../../../shared/button';
import {
  ButtonGroup,
  SideLabel,
} from '../../../../shared/layout/Layout.styles';
import { Input } from '../../../../shared/formElements';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DotsLoading } from '../../../../shared/loading';
import { useCreateFeedbackUserMutation } from '../../../../../generated/graphql';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';

interface IUserFormProps {
  firstName: string;
  lastName: string;
  email: string;
}

export const AddFeedbackUser = () => {
  const [apiError, setApiError] = useState('');
  const { appWidth, dispatch } = useContext(LayoutContext);
  const [
    createFeedbackUserMutation,
    { loading },
  ] = useCreateFeedbackUserMutation();
  const { register, handleSubmit, errors } = useForm<IUserFormProps>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(createValidationSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
    },
  });

  const handleCancel = () => {
    dispatch({ type: 'HIDE_TOOL_TIP' });
    dispatch({ type: 'TOGGLE_EXTRA_SLIDER' });
  };

  const onSubmit = async (formData: IUserFormProps) => {
    try {
      const response = await createFeedbackUserMutation({
        variables: {
          input: formData,
        },
        refetchQueries: ['useUsersQuery'],
        awaitRefetchQueries: true,
      });

      if (response.data?.createFeedbackUser.error) {
        setApiError(response.data.createFeedbackUser.error);
      }
      if (response.data?.createFeedbackUser.successful) {
        dispatch({ type: 'HIDE_TOOL_TIP' });
        dispatch({ type: 'TOGGLE_EXTRA_SLIDER' });
        dispatch({
          type: 'SET_POPUP',
          payload: {
            type: 'success',
            heading: 'Feedback User Created',
            message: `Feedback User was created Successfully`,
          },
        });
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  const sideLabel = (label: string) => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return '';
    }
    return <SideLabel>{label}</SideLabel>;
  };

  const fieldLabel = (label: string) => {
    if (appWidth !== 0 && appWidth < theme.mQ.tablet) {
      return label;
    }
    return '';
  };

  return (
    <LayoutPage
      backButton
      backButtonCTA={handleCancel}
      withLine
      heading="All Users"
      subHeading="Below are all your users across the whole platform."
      slider
      actionArray={
        appWidth !== 0 && appWidth < theme.mQ.tablet
          ? [
              <Button color="default" asCircle inversed onClick={handleCancel}>
                x
              </Button>,
            ]
          : [
              <Button color="default" inversed onClick={handleCancel}>
                Cancel
              </Button>,
              <Button color="secondary" onClick={handleSubmit(onSubmit)}>
                Save Feedback User
              </Button>,
            ]
      }
    >
      <S.Container>
        <S.Header>Feedback User Details</S.Header>
        <S.Form onSubmit={handleSubmit(onSubmit)}>
          {apiError && (
            <Span fontSize="small" color="faded" className="errorMessage">
              {apiError}
            </Span>
          )}
          <LayoutColumn>
            {sideLabel('First Name *')}
            <Input
              ref={register}
              label={fieldLabel('First Name *')}
              name="firstName"
              errorText={errors.firstName?.message}
            />
          </LayoutColumn>
          <LayoutColumn>
            {sideLabel('Last Name *')}
            <Input
              ref={register}
              label={fieldLabel('Last Name')}
              name="lastName"
              errorText={errors.lastName?.message}
            />
          </LayoutColumn>
          <LayoutColumn>
            {sideLabel('Email *')}
            <Input
              ref={register}
              label={fieldLabel('Email')}
              name="email"
              errorText={errors.email?.message}
            />
          </LayoutColumn>
        </S.Form>
      </S.Container>
      <ButtonGroup>
        <Button color="default" inversed onClick={handleCancel}>
          Cancel
        </Button>

        <Button
          color="secondary"
          onClick={handleSubmit(onSubmit)}
          disabled={loading}
        >
          <DotsLoading
            text={(loading) => `${loading ? 'Saving' : 'Save'} Feedback User`}
            isLoading={loading}
            size="small"
            lineHeight={10}
            noMargin
          />
        </Button>
      </ButtonGroup>
    </LayoutPage>
  );
};

const createValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().required('Email is required').email('Invalid email'),
});
