import styled from 'styled-components/macro';
import { Card } from '../../../../shared/card';

interface IUserHeaderProps {
  showUser: boolean;
}

export const Container = styled.div``;

export const UsersContainer = styled(Card)`
  padding-bottom: 0;
`;

export const UsersHeader = styled.section`
  background-color: ${({ theme }) => theme.colors.background};
  display: flex;
  align-items: center;
  min-height: 50px;
  border-radius: 14px 14px 0 0;
  padding: 5px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};
`;

export const User = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.faded};
`;

export const UserHeader = styled.li<IUserHeaderProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px 15px 15px;
  z-index: 1;
  box-shadow: ${({ showUser }) =>
    showUser ? '0px 5px 5px -3px rgba(0,0,0,0.6);' : 'none'};
`;

export const Users = styled.ul`
  display: block;
  padding-top: 5px !important;
  cursor: pointer;

  ${User}:last-child {
    border-bottom: none;
  }
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
`;

export const DropdownIcon = styled.div`
  margin: 5px 5px 0 15px;
`;

export const UserContent = styled.div`
  background-color: ${({ theme }) => theme.colors.default.transparent};
  padding: 30px 10px 20px 50px;
`;

export const ContentItem = styled.div`
  margin-bottom: 8px;
`;
