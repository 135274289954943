import { useContext } from 'react';
import { getKeyByValue, isEmpty, validEndDate } from '.';
import { IAccountStatProps } from '../../components/pages/account/account.types';
import { IVenueStatProps } from '../../components/pages/account/sections/venues/venues.types';
import { IPaidAddOnProps, LayoutContext } from '../../contexts/layoutContext';
import {
  AccountType,
  AddOn,
  AddOnApp,
  SubscriptionStatus,
  useMeQuery,
} from '../../generated/graphql';
import {
  AccountTypeRestrictionItem,
  checkRestrictionsRaw,
  RestrictionCheckerInput,
  RestrictionCheckerName,
} from './restrictionDsl';
import { useHighestProfitIncrease } from './useHighestProfitIncrease';
import { plans, plansArray } from './strings';

const ACCOUNT_TYPES: Array<{
  type: AccountType;
  name: string;
  restrictions: AccountTypeRestrictionItem[];
}> = [
  // Guest account type
  {
    type: AccountType.Guest,
    name: 'Active',
    restrictions: [
      {
        type: 'MAX_VENUES',
        conf: { maxVenues: 1 },
      },
      {
        type: 'SCALE_RECIPES',
        conf: { scaleRecipes: true },
      },
      {
        type: 'CALCULATE_RECIPE_PROFIT',
        conf: { canShowCalculatedRecipeProfit: true },
      },
      {
        type: 'FOOD_COST',
        conf: { canShowFoodCostPercentage: true },
      },
      // {
      //   type: 'MAX_PROFIT_INCREASE',
      //   conf: { maxProfitIncrease: 2000 },
      // },
      // {
      //   type: 'BULK_UPLOAD_INGREDIENTS',
      //   conf: { canBulkUpload: true },
      // },
    ],
  },

  // Free starter account type
  {
    type: AccountType.Registered,
    name: plans.freeStarter.heading,
    restrictions: [
      {
        type: 'MAX_VENUES',
        conf: { maxVenues: 1 },
      },
      {
        type: 'SCALE_RECIPES',
        conf: { scaleRecipes: false },
      },
      {
        type: 'MAX_RECIPE_VERSIONS',
        conf: { maxRecipeVersions: 3 },
      },
      {
        type: 'CALCULATE_RECIPE_PROFIT',
        conf: { canShowCalculatedRecipeProfit: false },
      },
      {
        type: 'FOOD_COST',
        conf: { canShowFoodCostPercentage: false },
      },
      // {
      //   type: 'MAX_PROFIT_INCREASE',
      //   conf: { maxProfitIncrease: 2000 },
      // },
      // {
      //   type: 'BULK_UPLOAD_INGREDIENTS',
      //   conf: { canBulkUpload: true },
      // },
    ],
  },

  // Recipe Plus account type
  {
    type: AccountType.RecipePlus,
    name: plans.recipePlus.heading,
    restrictions: [
      {
        type: 'SCALE_RECIPES',
        conf: { scaleRecipes: true },
      },
      {
        type: 'CALCULATE_RECIPE_PROFIT',
        conf: { canShowCalculatedRecipeProfit: false },
      },
      {
        type: 'FOOD_COST',
        conf: { canShowFoodCostPercentage: true },
      },
    ],
  },

  // Head Chef account type
  {
    type: AccountType.HeadChef,
    name: plans.headChef.heading,
    restrictions: [
      {
        type: 'SCALE_RECIPES',
        conf: { scaleRecipes: true },
      },
      {
        type: 'CALCULATE_RECIPE_PROFIT',
        conf: { canShowCalculatedRecipeProfit: true },
      },
      {
        type: 'FOOD_COST',
        conf: { canShowFoodCostPercentage: true },
      },
    ],
  },

  // Business owner account type
  {
    type: AccountType.Owner,
    name: plans.owner.heading,
    restrictions: [
      {
        type: 'SCALE_RECIPES',
        conf: { scaleRecipes: true },
      },
      {
        type: 'CALCULATE_RECIPE_PROFIT',
        conf: { canShowCalculatedRecipeProfit: true },
      },
      {
        type: 'FOOD_COST',
        conf: { canShowFoodCostPercentage: true },
      },
    ],
  },

  // Feedback User account type
  {
    type: AccountType.Feedback,
    name: 'Feedback Partner',
    restrictions: [
      {
        type: 'SCALE_RECIPES',
        conf: { scaleRecipes: true },
      },
      {
        type: 'CALCULATE_RECIPE_PROFIT',
        conf: { canShowCalculatedRecipeProfit: true },
      },
      {
        type: 'FOOD_COST',
        conf: { canShowFoodCostPercentage: true },
      },
    ],
  },

  // Recipe Revenue account type
  {
    type: AccountType.RecipeRevenue,
    name: 'Admin',
    restrictions: [],
  },
];

export const getAccountChangeDirection = (
  account: AccountType,
  currentAccount?: AccountType
) => {
  if (!account || !currentAccount) {
    return 'UP';
  }

  const accountKey = getKeyByValue(plansArray, 'type', account);
  const currentAccountKey = getKeyByValue(plansArray, 'type', currentAccount);

  if (accountKey < currentAccountKey) {
    return 'DOWN';
  }
  return 'UP';
};

export function checkRestrictions(args: {
  accountType: AccountType;
  currentAccountType?: AccountType;
  check?: RestrictionCheckerName[];
  input: RestrictionCheckerInput;
}) {
  const matchingType = getAccountTypeDetails(args.accountType);

  if (!matchingType) {
    throw new Error('account type not configured ' + args.accountType);
  }

  const filteredRestrictions = !args.check
    ? matchingType.restrictions
    : matchingType.restrictions.filter((item) =>
        args.check?.includes(item.type)
      );

  const direction = getAccountChangeDirection(
    args.accountType,
    args.currentAccountType
  );

  const restrictionsArray =
    direction === 'DOWN' && args.accountType === AccountType.Registered
      ? filteredRestrictions.filter((r) => r.type === 'MAX_VENUES')
      : filteredRestrictions;

  return checkRestrictionsRaw(restrictionsArray, args.input, direction);
}

export function useRestrictionCheck(check?: RestrictionCheckerName[]) {
  const { account } = useContext(LayoutContext);

  return (input: RestrictionCheckerInput, accountOverride?: AccountType) => {
    return checkRestrictions({
      accountType: accountOverride ?? account?.type ?? AccountType.Guest,
      currentAccountType: account?.type,
      check,
      input,
    });
  };
}

export function getAccountTypeName(type?: AccountType, defaultType?: string) {
  const matchingDetails = getAccountTypeDetails(type);
  return matchingDetails?.name ?? defaultType ?? '-';
}

function getAccountTypeDetails(type: AccountType | undefined) {
  for (const accType of ACCOUNT_TYPES) {
    if (accType.type === type) {
      return accType;
    }
  }
  return undefined;
}

export const useAccountStats = () => {
  const me = useMeQuery({ fetchPolicy: 'cache-and-network' });

  const venues = me.data?.me?.user?.venuesSharedWithYou || [];

  const highestWeeklyProfitIncrease = useHighestProfitIncrease();

  const result: IAccountStatProps = {
    totalRevenuePerYear: 0,
    avgMarkupPercentage: 0,
    avgRecipeProfit: 0,
    avgFoodCostPercentage: 0,
    bestRecipeRevenue: {
      displayName: '',
      revenue: 0,
    },
    totalWeeklyProfitIncrease: highestWeeklyProfitIncrease(),
  };

  if (isEmpty(venues)) {
    return result;
  }

  const resultArrays: {
    markupPercentages: number[];
    recipeProfits: number[];
    foodCostPercentages: number[];
  } = {
    markupPercentages: [],
    recipeProfits: [],
    foodCostPercentages: [],
  };

  const {
    markupPercentages,
    recipeProfits,
    foodCostPercentages,
  } = resultArrays;

  venues.forEach((venue) => {
    if (!isEmpty(venue.venue.recipes)) {
      venue.venue.recipes.forEach((recipe) => {
        const revenuePerYear =
          recipe.salesPricePerServe *
          recipe.weeklySalesPerServe *
          venue.venue.weeksOpen;
        result.totalRevenuePerYear =
          result.totalRevenuePerYear + revenuePerYear;
        const recipeCost = recipe.recipeRevenue - recipe.recipeProfit;
        if (recipeCost !== 0) {
          markupPercentages.push(
            (recipe.recipeRevenue - recipeCost) / recipeCost
          );
        }
        recipeProfits.push(recipe.recipeProfit);
        if (!isEmpty(recipe.foodCostPercentage)) {
          foodCostPercentages.push(recipe.foodCostPercentage);
        }
        if (result.bestRecipeRevenue.revenue < recipe.recipeRevenue) {
          result.bestRecipeRevenue = {
            displayName: recipe.displayName,
            revenue: recipe.recipeRevenue,
          };
        }
      });
    }
  });

  result.avgMarkupPercentage =
    markupPercentages.reduce((a, b) => a + b, 0) / markupPercentages.length;
  result.avgRecipeProfit =
    recipeProfits.reduce((a, b) => a + b, 0) / recipeProfits.length;
  result.avgFoodCostPercentage =
    foodCostPercentages.reduce((a, b) => a + b, 0) / foodCostPercentages.length;

  return result;
};

export const getVenueStats = (venue) => {
  const result: IVenueStatProps = {
    totalRevenuePerYear: 0,
    avgFoodCostPercentage: 0,
    totalRecipes: 0,
  };

  const resultArrays: {
    foodCostPercentages: number[];
  } = {
    foodCostPercentages: [],
  };

  const { foodCostPercentages } = resultArrays;

  if (!isEmpty(venue.recipes)) {
    venue.recipes.forEach((recipe) => {
      const revenuePerYear =
        recipe.salesPricePerServe *
        recipe.weeklySalesPerServe *
        venue.weeksOpen;
      result.totalRevenuePerYear = result.totalRevenuePerYear + revenuePerYear;
      if (!isEmpty(recipe.foodCostPercentage)) {
        foodCostPercentages.push(recipe.foodCostPercentage);
      }
    });
    result.avgFoodCostPercentage =
      foodCostPercentages.reduce((a, b) => a + b, 0) /
      foodCostPercentages.length;
    result.totalRecipes = venue.recipes.length;
  }
  return result;
};

export const getAccountStatus = (status?: SubscriptionStatus) => {
  switch (status) {
    case SubscriptionStatus.SubscriptionActive:
      return 'Active';
    case SubscriptionStatus.SubscriptionInactive:
      return 'Free';
    case SubscriptionStatus.SubscriptionPending:
      return 'Pending';
    default:
      return '-';
  }
};

export const checkIfAccountIsNotComplete = (
  email?: string,
  type?: AccountType
) => {
  if (type === AccountType.Guest) {
    return true;
  }
  return email
    ? email?.includes('guestuser') && email?.includes('reciperevenue')
    : false;
};

export const userHasCalculatorAddon = (
  addOns?: AddOn[] | IPaidAddOnProps[]
) => {
  const currentAddOn = addOns?.find((a) => {
    return (
      validEndDate(a.endDate).endDateIsValid && a.app === AddOnApp.Calculator
    );
  });
  return {
    validUserCalculatorAddOn: !!currentAddOn,
    currentCalculatorAddOn: currentAddOn,
  };
};
