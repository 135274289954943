import styled from 'styled-components/macro';
import { device } from '../../../../../styles/device';
import { Span } from '../../../../shared/typefaces/Typefaces.styles';

export const Container = styled.div``;

export const Discount = styled(Span)`
  padding: 0px 20px 10px 20px;
  text-align: right;
  width: 100%;
  min-height: 28px;
  display: block;
  color: ${({ theme }) => theme.colors.secondary.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.fontSize.button};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.default.transparent};
  border-top: 1px solid ${({ theme }) => theme.colors.default.light};
  border-left: 1px solid ${({ theme }) => theme.colors.default.light};
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.light};
  padding: 10px;
  min-width: 250px;
  border-radius: 8px 0 0 8px;

  .title {
    font-size: ${({ theme }) => theme.fontSize.button};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    color: ${({ theme }) => theme.colors.text.grey};
  }

  .icon {
    cursor: pointer;
  }

  @media ${device.tablet} {
    padding: 15px 10px;

    .title {
      font-weight: ${({ theme }) => theme.fontWeight.medium};
      color: ${({ theme }) => theme.colors.text.black};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.default.light};
  border-right: 1px solid ${({ theme }) => theme.colors.default.light};
  border-bottom: 1px solid ${({ theme }) => theme.colors.default.light};
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  .content {
    font-size: ${({ theme }) => theme.fontSize.button};
    margin-right: 10px;
    margin-bottom: 30px;
  }

  .valid {
    display: block;
    font-size: ${({ theme }) => theme.fontSize.button};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    color: ${({ theme }) => theme.colors.text.grey};
    margin-top: 5px;
  }

  .cta {
    min-width: 100px;
    margin-top: 30px;
  }

  @media ${device.tablet} {
    .content {
      font-size: ${({ theme }) => theme.fontSize.button};
      margin-right: 10px;
      margin-bottom: 0;
    }

    .cta {
      min-width: 100px;
      margin-top: 0;
    }
  }
`;

export const AddOn = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white.default};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.tinyBox};
  margin: 0 20px 10px;
  cursor: pointer;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadow.boxHover};

    ${Header} {
      border-top: 1px solid ${({ theme }) => theme.colors.default.default};
      border-left: 1px solid ${({ theme }) => theme.colors.default.default};
      border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};
    }

    ${Content} {
      border-top: 1px solid ${({ theme }) => theme.colors.default.default};
      border-right: 1px solid ${({ theme }) => theme.colors.default.default};
      border-bottom: 1px solid ${({ theme }) => theme.colors.default.default};
    }
  }

  @media ${device.tablet} {
    flex-direction: row;
  }
`;
